import { GOONO_UPLOAD_FILE_SIZE_LIMIT_BYTE } from "../../redux/container/FileContainer";
import { Dispatch } from "redux";
import translate from "@translate";
import { isInvalidNoteFileType } from "@utils/functions/checkNoteFileType";
import {
  DiaLogActionKind,
  DiaLogType,
  doDiaLogActionAsync,
} from "src/redux/store/reducers/dialog";

export const goono_upload_file_checker = async (props: {
  fileList: FileList | File[];
  dispatch?: Dispatch;
}): Promise<File[]> => {
  let invaild_extension_files: File[] = [];
  let over_size_files: File[] = [];
  let allowed_files: File[] = [];
  const ORIGINAL_FILE_LIST = [...props.fileList];

  ORIGINAL_FILE_LIST.map((file) => {
    const file_size_byte = file.size;
    if (isInvalidNoteFileType(file))
      invaild_extension_files = [...invaild_extension_files, file];
    else if (file_size_byte > GOONO_UPLOAD_FILE_SIZE_LIMIT_BYTE)
      over_size_files = [...over_size_files, file];
    else allowed_files = [...allowed_files, file];
  });

  const over_size_file_count = over_size_files.length;
  const invaild_extension_file_count = invaild_extension_files.length;
  let snackbar_title: undefined | string = undefined;
  let snackbar_msg: undefined | string = undefined;
  const had_all_exception =
    over_size_file_count > 0 && invaild_extension_file_count > 0;

  if (allowed_files.length === 0) {
    snackbar_title = translate.snackbar.file_upload_alert.none_file.title;
    if (had_all_exception) {
      snackbar_msg =
        translate.snackbar.file_upload_alert.none_file.size_and_extension_error;
    } else if (over_size_file_count > 0) {
      snackbar_msg = translate.snackbar.file_upload_alert.none_file.size_error;
    } else if (invaild_extension_file_count > 0) {
      snackbar_msg =
        translate.snackbar.file_upload_alert.none_file.extenstion_error;
    }
  } else {
    snackbar_title = translate.snackbar.file_upload_alert.common.title;
    if (had_all_exception) {
      snackbar_msg =
        translate.snackbar.file_upload_alert.common.size_and_extension_error(
          ORIGINAL_FILE_LIST.length -
            (over_size_file_count + invaild_extension_file_count)
        );
    } else if (over_size_file_count > 0) {
      snackbar_msg = translate.snackbar.file_upload_alert.common.size_error(
        ORIGINAL_FILE_LIST.length - over_size_file_count
      );
    } else if (invaild_extension_file_count > 0) {
      snackbar_msg =
        translate.snackbar.file_upload_alert.common.extenstion_error(
          ORIGINAL_FILE_LIST.length - invaild_extension_file_count
        );
    }
  }

  if (props.dispatch && snackbar_msg) {
    await doDiaLogActionAsync(props.dispatch, {
      kind: DiaLogActionKind.TRY_OPEN,
      type: DiaLogType.ERROR,
      title: snackbar_title,
      bodyMsg: snackbar_msg,
      confirmBtnMsg: translate.modal.button.confirm,
    });
  }

  return allowed_files;
};
