import { combineReducers } from "redux";
import persist from "./persist";
import token from "./token";
import project from "./project";
import note from "./note";
import gdt from "./google_drive_token";
import github from "./github";
import modal from "./modal";
import file from "./file";
import payment from "./payment";
import snackbar from "./snackbar";
import dialog from "./dialog";
import workspace from "./workspace";
import dataRoomTutorial from "./dataRoomTutorial";

const rootReducer = combineReducers({
  persist,
  token,
  project,
  note,
  gdt,
  github,
  modal,
  file,
  payment,
  snackbar,
  dialog,
  workspace,
  dataRoomTutorial,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
