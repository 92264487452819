import T from "@redwit-commons/utils/typecheck";
import { WorkspacePlanKind, WorkspaceRoleKind } from "../..//v3/workspace";
import { AccessTime, addAccessTime, withAccessTime } from "./access_time";
import { SNSPlatform } from "./user";

export type WorkspacePureCore = {
  readonly id: string;
  readonly name: string;
  readonly deactivated: boolean;
  readonly thumbCid?: string;
  readonly thumbExtension?: string;
  readonly thumbColor?: string;
  readonly institution?: string; // 내부관리자에서만 사용되는 필드
  readonly CipherId?: string;
  readonly OrganizationId?: string;
};

export type UserWorkspaceMapCore = {
  readonly UserId: string;
  readonly WorkspaceId: string;
  readonly role: WorkspaceRoleKind;
};
export type UserWorkspaceMapDBObject = UserWorkspaceMapCore & AccessTime;

export type WorkspaceMemberDBObject = {
  readonly id: string;
  readonly name: string;
  readonly email: string;
  readonly school: string;
  readonly department: string;
  readonly profile_cid: string;
  readonly profile_extension: string;
  readonly CipherId?: string;
} & AccessTime;
export type WorkspaceDBObjectCore = {
  readonly Users: Array<
    WorkspaceMemberDBObject & { User_Workspace_Map: UserWorkspaceMapCore }
  >;
  readonly WorkspaceInvitations: Array<WorkspaceInvitationObject>;
} & WorkspacePureCore;

export type WorkspaceObject = WorkspacePureCore & AccessTime;
export type WorkspaceDBObject = WorkspaceDBObjectCore & AccessTime;

export enum InvitationStatus {
  EXPIRE = "InvitationStatus::expire",
  OLD = "InvitationStatus::old",
  NEW = "InvitationStatus::new",
}

export type WorkspaceInvitationCore = {
  readonly id: string;
  readonly status: InvitationStatus;
  readonly email: string;
  readonly WorkspaceId: string;
  readonly role: WorkspaceRoleKind;
  readonly inviter_email?: string;
  readonly inviter_name?: string;
};
export type WorkspaceInvitationObject = WorkspaceInvitationCore & AccessTime;
export const WorkspaceInvitationObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("status", T.string())
  .addField("email", T.string())
  .addField("WorkspaceId", T.string())
  .addField(
    "role",
    T.string().withEnum([
      WorkspaceRoleKind.ADMIN,
      WorkspaceRoleKind.RESEARCHER,
      WorkspaceRoleKind.OWNER,
      WorkspaceRoleKind.EXTERNAL_RESEARCHER,
    ])
  )
  .addField("inviter_email", T.string(), false)
  .addField("inviter_name", T.string(), false);

export const extractWorkspaceInvitationObject =
  T.mkObjectExtractor<WorkspaceInvitationObject>(
    WorkspaceInvitationObjectSchema
  );

export const WorkspaceObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("deactivated", T.boolean())
  .addField("thumbCid", T.string(), false)
  .addField("thumbExtension", T.string(), false)
  .addField("thumbColor", T.string(), false)
  .addField("institution", T.string(), false)
  .addField("CipherId", T.string(), false)
  .addField("OrganizationId", T.string(), false);

export const WorkspaceMemberDBObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("school", T.string())
  .addField("department", T.string())
  .addField("profile_cid", T.string())
  .addField("profile_extension", T.string())
  .addField("CipherId", T.string(), false);

export const UserWorkspaceMapCoreSchema = T.object()
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string())
  .addField("role", T.string());
export const UserWorkspaceMapDBObjectSchema = addAccessTime(
  UserWorkspaceMapCoreSchema.clone()
);

export const WorkspaceDBObjectSchema = WorkspaceObjectSchema.clone()
  .addField(
    "Users",
    T.array(
      WorkspaceMemberDBObjectSchema.clone().addField(
        "User_Workspace_Map",
        UserWorkspaceMapCoreSchema.clone()
      )
    )
  )
  .addField(
    "WorkspaceInvitations",
    T.array(WorkspaceInvitationObjectSchema.clone()),
    false
  );
export const extractWorkspaceDBObject = T.mkObjectExtractor<WorkspaceDBObject>(
  WorkspaceDBObjectSchema
);

export type WorkspaceSummaryDBObject = {
  readonly Users: Array<
    WorkspaceMemberDBObject & {
      platform: SNSPlatform;
      User_Workspace_Map: UserWorkspaceMapCore;
    }
  >;
} & WorkspacePureCore;
export const WorkspaceSummaryDBObjectSchema =
  WorkspaceObjectSchema.clone().addField(
    "Users",
    T.array(
      WorkspaceMemberDBObjectSchema.clone()
        .addField("platform", T.string())
        .addField("User_Workspace_Map", UserWorkspaceMapCoreSchema.clone())
    )
  );
export const extractWorkspaceSummaryDBObject =
  T.mkObjectExtractor<WorkspaceSummaryDBObject>(WorkspaceSummaryDBObjectSchema);

export type WorkspacePlanCore = {
  readonly id: string;
  readonly type: WorkspacePlanKind;
  readonly seats: number;
  readonly start: string;
  readonly end: string;
  readonly price: string;
  readonly name: string;
  readonly description: string;
  readonly WorkspaceId: string;
  readonly enableACL?: boolean;
  readonly applyAdmin?: boolean;
};
export type WorkspacePlanObject = WorkspacePlanCore & AccessTime;
export const WorkspacePlanObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("type", T.string())
  .addField("seats", T.number())
  .addField("start", T.string())
  .addField("end", T.string())
  .addField("price", T.string())
  .addField("name", T.string())
  .addField("description", T.string())
  .addField("WorkspaceId", T.string())
  .addField("enableACL", T.boolean(), false)
  .addField("applyAdmin", T.boolean(), false);
export const extractWorkspacePlanObject =
  T.mkObjectExtractor<WorkspacePlanObject>(WorkspacePlanObjectSchema);

export function getWorkspaceRoleLevel(role?: WorkspaceRoleKind) {
  switch (role) {
    case WorkspaceRoleKind.OWNER:
      return 2;
    case WorkspaceRoleKind.ADMIN:
      return 1;
    case WorkspaceRoleKind.RESEARCHER:
    case WorkspaceRoleKind.EXTERNAL_RESEARCHER:
      return 0;
    default:
      return -1;
  }
}
