import { doAPIPostRequest, doAPIGetRequest } from "./api";

import {
  IIssueVBank,
  ICheckPayment,
  IIssueCard,
  IMakeTempPurchase,
} from "@goono-commons/api/request/payment";
import {
  OIssueVBank,
  validateOIssueVBank,
  OPaymentCheck,
  validateOPaymentCheck,
  validateOIssueCard,
  OIssueCard,
  OGetPendingPayment,
  validateOGetPendingPayment,
  OMakeTempPurchase,
  validateOMakePurchase,
  OGetTempPurchaseInfo,
  validateOGetPurchaseInfo,
  OGetTempPurchaseArray,
  validateOGetPurchaseArray,
} from "@goono-commons/api/response/payment";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";
import { getLoggers } from "@redwit-commons/utils/log";
const { WARN, INFO } = getLoggers("service/payment");

/**
 * GOONO 서버에 가상계좌 발급을 요청
 * @param goonoToken string
 * @param args IIssueVBank
 * @returns Promise<OIssueVBank>
 */
export const issueVBank = async (
  goonoToken: string,
  args: IIssueVBank
): Promise<OIssueVBank> => {
  const ret = await doAPIPostRequest("payment/vbank", goonoToken, args);
  return validateOIssueVBank(ret);
};

/** 결제창 링크를 통한 결제시 호출 */
export const issueCard = async (
  goonoToken: string,
  args: IIssueCard
): Promise<OIssueCard> => {
  const ret = await doAPIPostRequest("payment/card", goonoToken, args);
  return validateOIssueCard(ret);
};

/** 웹 개인결제시 호출 */
export const issuePersonalCard = async (
  goonoToken: string,
  workspaceId: string,
  args: IIssueCard
): Promise<OIssueCard> => {
  const ret = await doAPIPostRequest(
    `payment/dataroom/${workspaceId}`,
    goonoToken,
    args
  );
  return validateOIssueCard(ret);
};

export const checkPayment = async (
  goonoToken: string,
  args: ICheckPayment
): Promise<OPaymentCheck> => {
  const ret = await doAPIGetRequest("payment/check", goonoToken, args);
  return validateOPaymentCheck(ret);
};

export const getPendingPayment = async (
  goonoToken: string
): Promise<OGetPendingPayment | undefined> => {
  try {
    const ret = await doAPIGetRequest("payment/pending", goonoToken);
    return validateOGetPendingPayment(ret);
  } catch (err) {
    if (isErr(err)) {
      if (
        err.kind === InternalErrorKind.ResponseCode &&
        err.code >= 400 &&
        err.code < 500
      ) {
        INFO("NO Payment", err.code);
        return undefined;
      }
      WARN("진행중 결제를 가져오는 도중 에러 발생", err);
    }
    throw err;
  }
};

const makeCardToken = async (
  goonoToken: string,
  args: IMakeTempPurchase
): Promise<OMakeTempPurchase> => {
  const ret = await doAPIPostRequest("/payment/temp_token", goonoToken, args);
  return validateOMakePurchase(ret);
};

const getCardToken = async (
  goonoToken: string
): Promise<OGetTempPurchaseArray> => {
  const ret = await doAPIGetRequest("/payment/temp_token", goonoToken);
  return validateOGetPurchaseArray(ret);
};

export const getCardTokenInfo = async (
  goonoToken: string,
  paid_token: string
): Promise<OGetTempPurchaseInfo> => {
  const ret = await doAPIGetRequest("/payment/temp_token/info", goonoToken, {
    paid_token,
  });
  return validateOGetPurchaseInfo(ret);
};

export default {
  issueVBank,
  issueCard,
  issuePersonalCard,
  checkPayment,
  getPendingPayment,
  makeCardToken,
  getCardTokenInfo,
  getCardToken,
};
