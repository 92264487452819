import {
  imageExtensionList,
  heicExtensionList,
  officeExtensionList,
  pdfExtensionList,
  hwpExtensionList,
} from "@goono-commons/api/request/note";

const allowList = [
  ...imageExtensionList,
  ...heicExtensionList,
  ...officeExtensionList,
  ...pdfExtensionList,
  ...hwpExtensionList,
];

export function hasExtensionInFileName(filename: string): boolean {
  // 파일명이 없거나 점(.)이 없는 경우 false 반환
  if (!filename || !filename.includes(".")) {
    return false;
  }

  // 마지막 점(.) 이후의 문자열을 확장자로 간주
  const extension = filename.split(".").pop()?.toLowerCase();

  // 확장자가 허용된 목록에 있는지 확인
  return extension ? allowList.includes(extension) : false;
}
