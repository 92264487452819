import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import {
  ProjectAction,
  ProjectActionKind,
  ProjectState,
  projectStateMachine,
  ProjectStateMachineType,
  ProjectStateStatus,
} from "../store/reducers/project";
import moment from "moment";
import { IGetProjectActivity } from "@goono-commons/v3/request/activityLog";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.project,
    noteState: state.note,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ProjectContainerProps = PropsFromRedux & {
  stateMachine: ProjectStateMachineType;
};

export function handleProjectActions(
  prevState: ProjectState,
  action: ProjectAction
): ProjectState {
  switch (action.kind) {
    case ProjectActionKind.TRY_SELECT_PROJECT: {
      let prevActivityFilterOption = undefined;
      if (
        prevState.status === ProjectStateStatus.SELECT &&
        prevState.projectId === action.projectId
      )
        prevActivityFilterOption = prevState.activityFilterOption;
      return {
        ...prevState,
        ...action,
        status: ProjectStateStatus.SELECT,
        activityFilterOption: prevActivityFilterOption,
      };
    }

    case ProjectActionKind.TRY_UPDATE_ACTIVITY_FILTER: {
      const { newOption } = action;

      const newFilter: IGetProjectActivity = {
        ...prevState.activityFilterOption,
        ...newOption,
        afterAt: newOption?.afterAt
          ? moment(newOption.afterAt).startOf("D").toISOString()
          : prevState.activityFilterOption?.afterAt,
        beforeAt: newOption?.beforeAt
          ? moment(newOption.beforeAt).endOf("D").toISOString()
          : prevState.activityFilterOption?.beforeAt,
      };

      return {
        ...prevState,
        activityFilterOption: newOption !== undefined ? newFilter : undefined,
      };
    }

    case ProjectActionKind.TRY_UNSELECT_PROJECT:
      return {
        ...prevState,
        status: ProjectStateStatus.INIT,
        activityFilterOption: undefined,
      };

    case ProjectActionKind.TRY_UPDATE_SORT_ORDER:
      return { ...prevState, ...action };

    default:
      return { status: ProjectStateStatus.INIT };
  }
}

class ProjectContainer extends ReduxStateComponent3<ProjectContainerProps> {
  static defaultProps = {
    stateMachine: projectStateMachine,
  };

  constructor(props: ProjectContainerProps) {
    super(props);
  }

  protected onAction = handleProjectActions;
}

export default connector(ProjectContainer);
