import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum GDTStateStatus {
  INIT = "GDTState::INIT",
  SUCCESS = "GDTState::SUCCESS",
}

export enum GDTActionKind {
  TRY_SAVE_GDT_INFO = "GDTAction::TRY_SAVE_GDT_INFO",
  TRY_SET_PROJECT = "GDTAction::TRY_SET_PROJECT",
  TRY_RESET_PROJECT = "GDTAction::TRY_RESET_PROJECT",
  TRY_RESET_ALL = "GDTAction::TRY_RESET_ALL",
}

export interface GDTStateTrait {
  access_token: string;
  refresh_token: string;
  expires_in: string;
}

export type GDTError = never;

export type GDTState =
  | {
      readonly status: GDTStateStatus.INIT;
      readonly project?: { id: string; name: string };
      readonly folderId?: string;
    }
  | ({
      readonly status: GDTStateStatus.SUCCESS;
      readonly project?: { id: string; name: string };
      readonly folderId?: string;
    } & GDTStateTrait);

export type GDTAction =
  | {
      readonly kind: GDTActionKind.TRY_SAVE_GDT_INFO;
      readonly access_token: string;
      readonly refresh_token: string;
      readonly expires_in: string;
    }
  | {
      readonly kind: GDTActionKind.TRY_RESET_PROJECT;
    }
  | {
      readonly kind: GDTActionKind.TRY_SET_PROJECT;
      readonly project?: { id: string; name: string };
      readonly folderId?: string;
    }
  | {
      readonly kind: GDTActionKind.TRY_RESET_ALL;
    };

const smid = "GDT_STATE_MACHINE3";
export type GDTStateMachineType = StateMachine3<
  GDTStateStatus,
  GDTState,
  GDTActionKind,
  GDTAction,
  GDTError
>;
export const GDTStateMachine: GDTStateMachineType = new StateMachine3<
  GDTStateStatus,
  GDTState,
  GDTActionKind,
  GDTAction,
  GDTError
>(smid, { status: GDTStateStatus.INIT }, [
  transition(
    GDTStateStatus.SUCCESS,
    GDTStateStatus.SUCCESS,
    GDTActionKind.TRY_RESET_PROJECT
  ),
  transition(
    GDTStateStatus.INIT,
    GDTStateStatus.INIT,
    GDTActionKind.TRY_RESET_PROJECT
  ),
  transition(
    GDTStateStatus.SUCCESS,
    GDTStateStatus.SUCCESS,
    GDTActionKind.TRY_SET_PROJECT
  ),
  transition(
    GDTStateStatus.INIT,
    GDTStateStatus.SUCCESS,
    GDTActionKind.TRY_SAVE_GDT_INFO
  ),
  transition(
    GDTStateStatus.SUCCESS,
    GDTStateStatus.INIT,
    GDTActionKind.TRY_RESET_ALL
  ),
]);

export type DispatchGDTAction = Dispatch<
  StateMachineAction<
    GDTStateStatus,
    GDTState,
    GDTActionKind,
    GDTAction,
    GDTError
  >
>;
export default mkReducer<
  GDTStateStatus,
  GDTState,
  GDTActionKind,
  GDTAction,
  GDTError
>(GDTStateMachine);

export const doGDTAction = (
  dispatch: DispatchGDTAction,
  nextAction: GDTAction,
  onResolve: () => void = () => {},
  onReject: (err: GDTError | InternalError) => void = () => {}
) => {
  dispatch(GDTStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doGDTActionAsync = (
  dispatch: DispatchGDTAction,
  nextAction: GDTAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(GDTStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetGDT = (dispatch: DispatchGDTAction) => {
  dispatch(GDTStateMachine.newResetAction());
};
