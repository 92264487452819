import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import {
  NoteStateStatus,
  NoteState,
  NoteAction,
  NoteActionKind,
  NoteStateMachineType,
  noteStateMachine,
} from "../store/reducers/note";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";

const mapStateToProps = (state: RootState) => {
  return { reduxState: state.note, token: state.token };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type NoteContainerProps = PropsFromRedux & {
  stateMachine: NoteStateMachineType;
};

export const handleNoteAction = (
  prevState: NoteState,
  action: NoteAction
): NoteState => {
  switch (action.kind) {
    case NoteActionKind.TRY_UPDATE_FOLDER_SORT_ORDER: {
      return { ...prevState, ...action };
    }

    case NoteActionKind.TRY_UPDATE_EXCLUDE_NOTE_ID_LIST: {
      if (prevState.status === NoteStateStatus.SUCCESS_SELECT)
        return {
          ...prevState,
          status: NoteStateStatus.SUCCESS_SELECT,
          excludeNoteIdList: action.newExcludeIdList,
        };
      else return prevState;
    }

    case NoteActionKind.TRY_RESET_ALL: {
      const { selectMode, resetSearchOption } = action;
      return {
        selectMode,
        listType: prevState.listType,
        status: NoteStateStatus.INIT,
        selectedFolders: [],
        notesSortingOption: resetSearchOption
          ? "lastUploaded"
          : prevState.notesSortingOption,
        notesFilterOptions: resetSearchOption
          ? {
              dateFilterOption: undefined,
              afterAt: undefined,
              beforeAt: undefined,
              authors: undefined,
              extensions: undefined,
              tags: undefined,
              ocrText: undefined,
            }
          : prevState.notesFilterOptions,
      };
    }

    case NoteActionKind.TRY_UPDATE_GRID: {
      return { ...prevState, listType: action.type };
    }

    case NoteActionKind.TRY_SELECT_FOLDER: {
      return {
        ...prevState,
        status: NoteStateStatus.SUCCESS_SELECT,
        selectedFolders: action.selectedFolders,
        selects:
          prevState.status === NoteStateStatus.SUCCESS_SELECT
            ? prevState.selects
            : [],
      };
    }

    case NoteActionKind.TRY_SELECT_NOTE: {
      const oldAllSelectNotes =
        prevState.status === NoteStateStatus.SUCCESS_SELECT
          ? prevState.allSelectNotes
          : false;
      return {
        ...prevState,
        allSelectNotes: action.selects.length === 0 ? false : oldAllSelectNotes,
        status: NoteStateStatus.SUCCESS_SELECT,
        selects: action.selects,
      };
    }

    case NoteActionKind.TRY_ALL_SELECT_NOTE: {
      return {
        ...prevState,
        selects: [],
        excludeNoteIdList: [],
        status: NoteStateStatus.SUCCESS_SELECT,
        allSelectNotes: true,
      };
    }

    case NoteActionKind.TRY_UPDATE_NOTES_FILTER_OPTIONS: {
      if (action.options === undefined) {
        return {
          ...prevState,
          notesFilterOptions: undefined,
        };
      }
      return {
        ...prevState,
        notesFilterOptions: {
          ...prevState.notesFilterOptions,
          ...action.options,
        },
      };
    }

    case NoteActionKind.TRY_UPDATE_NOTES_SORTING_OPTION: {
      return {
        ...prevState,
        notesSortingOption: action.option,
      };
    }

    case NoteActionKind.TRY_UPDATE_SELECT_MODE: {
      const { mode } = action;
      return {
        ...prevState,
        selectMode: mode,
      };
    }

    default: {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "NoteActionKind::default",
        msg: "Unknown action",
        action,
      });
    }
  }
};

class NoteContainer extends ReduxStateComponent3<NoteContainerProps> {
  static defaultProps = {
    stateMachine: noteStateMachine,
  };
  constructor(props: NoteContainerProps) {
    super(props);
  }

  protected onAction = handleNoteAction;
}

export default connector(NoteContainer);
