import { IpfsData } from "@goono-commons/api/object/ipfs";
import { uploadFileIPFSWithEncryption } from "@goono-react-commons/services/ipfs";

export const switchFileToIPFSData = async (
  token: string,
  file: File
): Promise<IpfsData> => {
  const extension = (
    file.name.split(".").slice(-1)[0] ?? "unknown"
  ).toLowerCase();

  const res = await uploadFileIPFSWithEncryption(token, file, extension);

  return {
    cid: res.cid,
    extension: res.extension,
    CipherId: res.CipherId,
  };
};
