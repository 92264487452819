import { GoonoResponse, mkResponseSchema } from "./common";
import T from "@redwit-commons/utils/typecheck";
import {
  PaymentDBObject,
  PaymentDBObjectSchema,
  WorkspacePlanPurchaseInfo,
  WorkspacePlanPurchaseInfoSchema,
} from "../object/payment";
import {
  TempPurchaseObject,
  TempPurchaseObjectArraySchema,
} from "../object/payment";

export type OIssueVBank = GoonoResponse<PaymentDBObject>;
export type OIssueCard = GoonoResponse<PaymentDBObject>;

export const OIssueVBankSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOIssueVBank =
  T.mkValidator<OIssueVBank>(OIssueVBankSchema);
export const OIssueCardSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOIssueCard = T.mkValidator<OIssueCard>(OIssueCardSchema);

export type OWebHook = GoonoResponse<PaymentDBObject>;
export type OPaymentCheck = GoonoResponse<PaymentDBObject>;
export const OWebHookSchema = OIssueVBankSchema.clone();
export const OPaymentCheckSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOPaymentCheck =
  T.mkValidator<OPaymentCheck>(OPaymentCheckSchema);

export type OGetPendingPayment = GoonoResponse<PaymentDBObject>;
export const OGetPendingPaymentSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOGetPendingPayment = T.mkValidator<OGetPendingPayment>(
  OGetPendingPaymentSchema
);

export type OMakeTempPurchase = GoonoResponse<string>;
export const OMakeTempPurchaseSchema = mkResponseSchema(T.string());
export const validateOMakePurchase = T.mkValidator<OMakeTempPurchase>(
  OMakeTempPurchaseSchema
);

export type OGetTempPurchaseInfo = GoonoResponse<WorkspacePlanPurchaseInfo>;
export const OGetTempPurchaseInfoSchema = mkResponseSchema(
  WorkspacePlanPurchaseInfoSchema
);
export const validateOGetPurchaseInfo = T.mkValidator<OGetTempPurchaseInfo>(
  OGetTempPurchaseInfoSchema
);

export type OGetTempPurchaseArray = GoonoResponse<TempPurchaseObject[]>;
export const OGetTempPurchaseArraySchema = mkResponseSchema(
  TempPurchaseObjectArraySchema
);
export const validateOGetPurchaseArray = T.mkValidator<OGetTempPurchaseArray>(
  OGetTempPurchaseArraySchema
);
