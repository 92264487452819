import React from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/reducers";
import { TokenStateStatus } from "../../redux/store/reducers/token";
import {
  WorkspaceActionKind,
  WorkspaceMenu,
  WorkspaceStateStatus,
  doWorkspaceAction,
} from "src/redux/store/reducers/workspace";
import ActiveLoadingView from "src/components/public/view/ActiveLoadingView";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";
import { useGetJoinedWorkspaceListQuery } from "@react-query/api/workspace";
import { useCurrentWorkspaceMenu } from "@utils/hooks/service/useCurrentWorkspaceMenu";
import ResearchNoteDashboardScreenSkeletonView from "src/components/workspace/WorkspaceDashboardSkeletonView";

const OnboardingScreenLazy = React.lazy(
  () => import("../../screens/user/GuestOnboardingScreen/index")
);

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const pathname = history.location.pathname;

  const joinedWorkspaceListQuery = useGetJoinedWorkspaceListQuery();
  const currentWorkspaceMenu = useCurrentWorkspaceMenu();

  const tokenState = useSelector((state: RootState) => state.token).state;

  const userHasSign =
    tokenState.status === TokenStateStatus.SUCCESS &&
    tokenState.user_sign_cid !== undefined &&
    tokenState.user_sign_extension !== undefined;

  const workspaceState = useSelector(
    (state: RootState) => state.workspace
  ).state;

  /** 워크스페이스가 없어도 접근 가능한 path 배열 */
  const do_not_require_workspace_path_list: string[] = [
    ScreenURL.WORKSPACE_EMPTY,
    ScreenURL.WORKSPACE_CREATE,
    ScreenURL.MYPAGE,
    ScreenURL.CUSTOM_PURCHASE,
    ScreenURL.PAYMENT_RESULT,
  ];

  React.useEffect(() => {
    const updateWorkspaceMenu = (service: WorkspaceMenu) => {
      doWorkspaceAction(dispatch, {
        kind: WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE_SERVICE,
        service,
      });
    };

    if (
      pathname.includes(`research-note`) &&
      currentWorkspaceMenu !== WorkspaceMenu.RESEARCH_NOTE
    ) {
      updateWorkspaceMenu(WorkspaceMenu.RESEARCH_NOTE);
    } else if (
      pathname.includes(`data-room`) &&
      currentWorkspaceMenu !== WorkspaceMenu.DATA_ROOM
    ) {
      updateWorkspaceMenu(WorkspaceMenu.DATA_ROOM);
    }
  }, []);

  React.useEffect(() => {
    if (tokenState.status !== TokenStateStatus.SUCCESS)
      history.push(ScreenURL.SIGN_IN);
  }, []);

  React.useEffect(() => {
    if (
      joinedWorkspaceListQuery.isSuccess &&
      joinedWorkspaceListQuery.data.workspaceList.length === 0 &&
      !do_not_require_workspace_path_list.includes(history.location.pathname)
    )
      history.push(ScreenURL.WORKSPACE_EMPTY);
  }, [joinedWorkspaceListQuery.data]);

  const renderEmptyGoonoBaseGrid = () => {
    return (
      <GoonoBaseGrid
        children={
          history.location.pathname === ScreenURL.RESEARCH_NOTE_DASHBOARD ? (
            <ResearchNoteDashboardScreenSkeletonView />
          ) : undefined
        }
        viewerOption={{
          type: "auth_page",
          ...(workspaceState.status === WorkspaceStateStatus.INIT
            ? {
                sidebarOptions: {
                  hasLocalMenu: false,
                },
                headerOptions: {
                  hasSearchForm: false,
                },
              }
            : {}),
        }}
      />
    );
  };

  if (tokenState.status !== TokenStateStatus.SUCCESS)
    return <ActiveLoadingView />;

  if (joinedWorkspaceListQuery.isLoading) return renderEmptyGoonoBaseGrid();

  if (userHasSign === false)
    return (
      <React.Suspense fallback={<ActiveLoadingView />}>
        <OnboardingScreenLazy />
      </React.Suspense>
    );

  /** do_not_require_workspace_path_list를 제외한 나머지 path에서 워크스페이스 정보를 보장하는 조건문 */
  if (
    !do_not_require_workspace_path_list.includes(history.location.pathname) &&
    workspaceState.status !== WorkspaceStateStatus.SUCCESS
  )
    return renderEmptyGoonoBaseGrid();

  return (
    <React.Suspense fallback={renderEmptyGoonoBaseGrid()}>
      <Route {...rest} />
    </React.Suspense>
  );
};
