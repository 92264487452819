// @flow
import React from "react";
import TokenContainer from "./TokenContainer";
import NoteContainer from "./NoteContainer";
import ProjectContainer from "./ProjectContainer";
import GDTContainer from "./GDTContainer";
import GithubContainer from "./GithubContainer";
import ModalContainer from "./ModalContainer";
import FileContainer from "./FileContainer";
import PaymentContainer from "./PaymentContainer";
import SnackbarContainer from "./SnackbarContainer";
import DialogContainer from "./DialogContainer";
import PersistContainer from "./PersistContainer";
import WorkspaceContainer from "./WorkspaceContainer";
import DataRoomTutorialContainer from "./DataRoomTutorialContainer";

// 다양한 container 들을 포함하도록 감싸줌.
export default class ContainerProvider extends React.PureComponent {
  render() {
    return (
      <>
        <TokenContainer />
        <ProjectContainer />
        <NoteContainer />
        <GDTContainer />
        <GithubContainer />
        <ModalContainer />
        <FileContainer />
        <PaymentContainer />
        <SnackbarContainer />
        <DialogContainer />
        <PersistContainer />
        <WorkspaceContainer />
        <DataRoomTutorialContainer />
        {this.props.children}
      </>
    );
  }
}
