import {
  heicExtensionList,
  imageExtensionList,
  pdfExtensionList,
  officeExtensionList,
} from "@goono-commons/api/request/note";

export const allowedExtends = [
  ...imageExtensionList,
  ...heicExtensionList,
  ...pdfExtensionList,
  ...officeExtensionList,
];

export function isValidExtension(extension: string): boolean {
  return allowedExtends.includes(extension.toLowerCase());
}

export function getFileExtension(file: File) {
  return file.name.split(".").slice(-1)[0].toLowerCase();
}

export function isInvalidNoteFileType(file: File): boolean {
  const fileType = getFileExtension(file);
  return !allowedExtends.includes(fileType);
}
