import {
  Workspace,
  WorkspaceMemberInfo,
  WorkspacePlan,
  WorkspaceRoleKind,
} from "@goono-commons/v3/workspace";
import { WorkspaceStateStatus } from "../../../redux/store/reducers/workspace";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import moment from "moment";
import { useLoginUserInfo } from "./useLoginUserInfo";
import { useGetWorkspaceAcessAllowQuery } from "@react-query/api/workspace";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

type WorkspaceCore = {
  externalResearchers: WorkspaceMemberInfo[];
  role: WorkspaceRoleKind;
} & Omit<Workspace, "plan">;

export type CustomWorkspaceInfo =
  | ({
      hasPlan: true;
      planInfo: CustomWorkspacePlanInfo;
    } & WorkspaceCore)
  | ({
      hasPlan: false;
    } & WorkspaceCore);

export type CustomWorkspacePlanInfo = {
  isExpired: boolean;
  /** 예정 플랜인지 */
  isExpectedPlan: boolean;
  expiredDday: number;
} & WorkspacePlan;

/**
 * WorkspaceStateStatus SUCCESS가 보장되는 곳에서만 사용해주세요
 * 보장 안되는 곳 : 사이드바 등등.. 따로 가드가 없는 곳
 * */
export const useWorkspaceInfo = (): CustomWorkspaceInfo => {
  const history = useHistory();

  const workspaceState = useSelector(
    (state: RootState) => state.workspace
  ).state;

  const workspace =
    workspaceState.status === WorkspaceStateStatus.SUCCESS
      ? workspaceState.currentWorkspace
      : undefined;

  const workspaceAcessAllowQuery = useGetWorkspaceAcessAllowQuery(workspace);

  useEffect(() => {
    const exceptionUrl: string[] = [
      ScreenURL.WORKSPACE_CREATE,
      ScreenURL.MYPAGE,
      ScreenURL.WORKSPACE_IP_BLOCKING,
    ];

    if (
      workspaceAcessAllowQuery.isSuccess &&
      workspaceAcessAllowQuery.data === false &&
      !exceptionUrl.includes(history.location.pathname)
    )
      history.push(ScreenURL.WORKSPACE_IP_BLOCKING);
  }, [workspaceAcessAllowQuery.data]);

  const userInfo = useLoginUserInfo();

  function getPlanExpiredDDay(planEndDate: string) {
    const endDate = moment(planEndDate);
    if (moment().isAfter(endDate)) return 0;
    return Math.abs(moment().diff(endDate.add(1, "d"), "d"));
  }

  if (workspaceState.status !== WorkspaceStateStatus.SUCCESS) throw Error;

  const workspaceRole = workspaceState.currentWorkspace.members.find(
    (member) => member.id === userInfo.id
  )?.role as WorkspaceRoleKind;

  const externalResearchers = workspaceState.currentWorkspace.members.filter(
    (member) => member.role === WorkspaceRoleKind.EXTERNAL_RESEARCHER
  );

  const membersWithoutExternalResearcher =
    workspaceState.currentWorkspace.members.filter(
      (member) => member.role !== WorkspaceRoleKind.EXTERNAL_RESEARCHER
    );

  /** 요금제 없는 경우 */
  if (workspaceState.currentWorkspace.plan === undefined) {
    return {
      ...workspaceState.currentWorkspace,
      externalResearchers,
      members: membersWithoutExternalResearcher,
      hasPlan: false,
      role: workspaceRole,
    };
  }

  const planExpiredDDay = getPlanExpiredDDay(
    workspaceState.currentWorkspace.plan.period.endAt
  );

  /** 요금제 있는 경우 */
  return {
    ...workspaceState.currentWorkspace,
    externalResearchers,
    hasPlan: true,
    members: membersWithoutExternalResearcher,
    role: workspaceRole,
    planInfo: {
      isExpectedPlan: moment(
        workspaceState.currentWorkspace.plan.period.startAt
      )
        .startOf("D")
        .isAfter(moment()),
      isExpired: planExpiredDDay < 1,
      expiredDday: planExpiredDDay,
      ...workspaceState.currentWorkspace.plan,
    },
  };
};
