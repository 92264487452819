import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  FileAction,
  FileActionKind,
  FileState,
  fileStateMachine,
  FileStateMachineType,
  FileStateStatus,
} from "../store/reducers/file";
import { goono_upload_file_checker } from "@utils/functions/goonoUploadFileChecker";
import { Dispatch } from "redux";

const mapStateToProps = (state: RootState) => {
  return { reduxState: state.file };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FileContainerProps = PropsFromRedux & {
  stateMachine: FileStateMachineType;
};

export const GOONO_UPLOAD_FILE_SIZE_LIMIT_BYTE = 500 * 1024 ** 2;

export const handleFileAction = async (
  prevState: FileState,
  action: FileAction,
  dispatch?: Dispatch
): Promise<FileState> => {
  switch (action.kind) {
    case FileActionKind.TRY_FETCH: {
      const { project, folderId, files } = action;

      const allowedFiles = await goono_upload_file_checker({
        fileList: files,
        dispatch,
      });

      if (allowedFiles.length === 0) {
        if (prevState.files.length === 0) throw Error("not allowed file");
        else return prevState;
      }
      const newFiles: File[] = [...prevState.files];
      const nameSet = new Set(newFiles.map((f) => f.name));
      allowedFiles.map((f) => !nameSet.has(f.name) && newFiles.push(f));

      if (project !== undefined)
        return {
          status: FileStateStatus.INIT,
          files: newFiles,
          project,
          folderId,
        };
      return { status: FileStateStatus.INIT, files: newFiles };
    }
    case FileActionKind.TRY_REMOVE_FILE: {
      const { files } = prevState;
      const updatedFiles = files.filter(
        (file) => file.name !== action.file.name
      );
      return { status: FileStateStatus.INIT, files: updatedFiles };
    }
    case FileActionKind.TRY_RESET: {
      const newFiles: File[] = [];
      return {
        status: FileStateStatus.INIT,
        files: newFiles,
        project: undefined,
        folderId: undefined,
      };
    }
    case FileActionKind.TRY_START_FILE_UPLOAD: {
      return {
        ...prevState,
        status: FileStateStatus.UPLOADING,
      };
    }
    case FileActionKind.TRY_FINISH_FILE_UPLOAD: {
      return {
        ...prevState,
        status: FileStateStatus.INIT,
      };
    }
  }
};

class FileContainer extends ReduxStateComponent3<FileContainerProps> {
  static defaultProps = { stateMachine: fileStateMachine };
  constructor(props: FileContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: FileState,
    action: FileAction
  ): Promise<FileState> {
    return await handleFileAction(prevState, action, this.props.dispatch);
  }
}

export default connector(FileContainer);
