import { omit } from "lodash";
import {
  LoginUserInfo,
  TokenStateStatus,
} from "../../../redux/store/reducers/token";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { IpfsData } from "@goono-commons/api/object/ipfs";

type Result = {
  profileImage: IpfsData;
} & LoginUserInfo;

export const useLoginUserInfo = (): Result => {
  const tokenState = useSelector((state: RootState) => state.token).state;

  if (tokenState.status !== TokenStateStatus.SUCCESS) throw Error;

  return {
    ...omit(tokenState, "status"),
    profileImage: {
      cid: tokenState.profile_cid,
      extension: tokenState.profile_extension,
      CipherId: tokenState.CipherId,
    },
  };
};
