import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { NoteObject } from "@goono-commons/api/object/note";
import { IGetNotes } from "@goono-commons/api/request/note";
import { DateFilterOptionKind } from "src/components/public/filter/DateFilter";
import { SortingOptionType } from "src/screens/research-note/ProjectInsideScreen/ResearchFileContents/FilterRow/FileSortingSelector";
import { SortingFilterOptions } from "@utils/functions/sortingFilter";

export enum NoteStateStatus {
  INIT = "NoteState::INIT",
  SUCCESS_SELECT = "NoteState::SUCCESS_SELECT",
}

export enum NoteActionKind {
  TRY_UPDATE_GRID = "NoteAction::TRY_UPDATE_GRID",
  TRY_SELECT_NOTE = "NoteAction::TRY_SELECT_NOTE",
  TRY_SELECT_FOLDER = "NoteAction::TRY_SELECT_FOLDER",
  TRY_ALL_SELECT_NOTE = "NoteAction::TRY_ALL_SELECT",
  TRY_UPDATE_NOTES_FILTER_OPTIONS = "NoteAction::TRY_UPDATE_NOTES_FILTER_OPTIONS",
  TRY_UPDATE_NOTES_SORTING_OPTION = "NoteAction::TRY_UPDATE_NOTES_SOTRING_OPTION",
  TRY_UPDATE_SELECT_MODE = "NoteAction::TRY_UPDATE_SELECT_MODE",
  TRY_RESET_ALL = "NoteAction::TRY_RESET_ALL",
  /** 전체 선택 시 제외할 파일 id 목록을 업데이트 하는 액션 */
  TRY_UPDATE_EXCLUDE_NOTE_ID_LIST = "NoteAction::TRY_UPDATE_EXCLUDE_NOTE_ID_LIST",
  TRY_UPDATE_FOLDER_SORT_ORDER = "NoteAction::TRY_UPDATE_FOLDER_SORT_ORDER",
}

export type NoteListType = "card" | "table";

export type NoteFilterOptionType = IGetNotes & {
  dateFilterOption?: DateFilterOptionKind;
};

export type SelectMode = "none" | "download" | "move" | "delete";

export enum SelectModeType {
  NONE = "none",
  DOWNLOAD = "download",
  MOVE = "move",
  DELETE = "delete",
}

interface NoteStateTrait {
  readonly folderSortingOption?: SortingFilterOptions;
  readonly selectMode: SelectMode;
  readonly listType: "card" | "table";
  readonly selectedFolders: Array<string>;
  readonly notesFilterOptions?: NoteFilterOptionType;
  readonly notesSortingOption: SortingOptionType;
}

export type NoteError = never;

export type NoteState =
  | ({
      readonly status: NoteStateStatus.INIT;
    } & NoteStateTrait)
  | ({
      readonly status: NoteStateStatus.SUCCESS_SELECT;
      readonly selects: Array<NoteObject>;

      readonly allSelectNotes?: boolean;
      /** 전체 선택 시 제외할 파일 id 목록 */
      readonly excludeNoteIdList?: string[];
    } & NoteStateTrait);

export type NoteAction =
  | {
      readonly kind: NoteActionKind.TRY_UPDATE_GRID;
      readonly type: NoteListType;
    }
  | {
      readonly kind: NoteActionKind.TRY_SELECT_NOTE;
      readonly selects: NoteObject[];
    }
  | {
      readonly kind: NoteActionKind.TRY_SELECT_FOLDER;
      readonly selectedFolders: string[];
    }
  | {
      readonly kind: NoteActionKind.TRY_ALL_SELECT_NOTE;
    }
  | {
      readonly kind: NoteActionKind.TRY_UPDATE_NOTES_FILTER_OPTIONS;
      readonly options?: NoteFilterOptionType;
    }
  | {
      readonly kind: NoteActionKind.TRY_UPDATE_NOTES_SORTING_OPTION;
      readonly option: SortingOptionType;
    }
  | {
      readonly kind: NoteActionKind.TRY_UPDATE_SELECT_MODE;
      readonly mode: SelectMode;
    }
  | {
      readonly kind: NoteActionKind.TRY_RESET_ALL;
      readonly selectMode: SelectMode;
      readonly resetSearchOption?: boolean;
    }
  | {
      readonly kind: NoteActionKind.TRY_UPDATE_EXCLUDE_NOTE_ID_LIST;
      readonly newExcludeIdList: string[];
    }
  | {
      readonly kind: NoteActionKind.TRY_UPDATE_FOLDER_SORT_ORDER;
      readonly folderSortingOption?: SortingFilterOptions;
    };

const smid = "NOTE_STATE_MACHINE3";
export type NoteStateMachineType = StateMachine3<
  NoteStateStatus,
  NoteState,
  NoteActionKind,
  NoteAction,
  NoteError
>;
export const noteStateMachine: NoteStateMachineType = new StateMachine3<
  NoteStateStatus,
  NoteState,
  NoteActionKind,
  NoteAction,
  NoteError
>(
  smid,
  {
    selectMode: "none",
    listType: "table",
    status: NoteStateStatus.INIT,
    selectedFolders: [],
    notesSortingOption: "lastUploaded",
    notesFilterOptions: {
      dateFilterOption: undefined,
      afterAt: undefined,
      beforeAt: undefined,
      authors: undefined,
      extensions: undefined,
      tags: undefined,
      ocrText: undefined,
      fileName: undefined,
    },
  },
  [
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_SELECT_NOTE
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_SELECT_NOTE
    ), // 멀티 셀렉트가 되야하므로 필요함
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_ALL_SELECT_NOTE
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_ALL_SELECT_NOTE
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_UPDATE_NOTES_FILTER_OPTIONS
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_NOTES_FILTER_OPTIONS
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_UPDATE_NOTES_SORTING_OPTION
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_NOTES_SORTING_OPTION
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_SELECT_MODE
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_UPDATE_SELECT_MODE
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_SELECT_MODE
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_SELECT_FOLDER
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_SELECT_FOLDER
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_RESET_ALL
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_RESET_ALL
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_UPDATE_GRID
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_GRID
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_EXCLUDE_NOTE_ID_LIST
    ),
    transition(
      NoteStateStatus.INIT,
      NoteStateStatus.INIT,
      NoteActionKind.TRY_UPDATE_FOLDER_SORT_ORDER
    ),
    transition(
      NoteStateStatus.SUCCESS_SELECT,
      NoteStateStatus.SUCCESS_SELECT,
      NoteActionKind.TRY_UPDATE_FOLDER_SORT_ORDER
    ),
  ]
);
/** */
export type DispatchNoteAction = Dispatch<
  StateMachineAction<
    NoteStateStatus,
    NoteState,
    NoteActionKind,
    NoteAction,
    NoteError
  >
>;
export default mkReducer<
  NoteStateStatus,
  NoteState,
  NoteActionKind,
  NoteAction,
  NoteError
>(noteStateMachine);

export const doNoteAction = (
  dispatch: DispatchNoteAction,

  nextAction: NoteAction,
  onResolve: () => void = () => {},
  onReject: (err: NoteError | InternalError) => void = () => {}
) => {
  dispatch(noteStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doNoteActionAsync = (
  dispatch: DispatchNoteAction,
  nextAction: NoteAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(noteStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetNote = (dispatch: DispatchNoteAction) => {
  dispatch(noteStateMachine.newResetAction());
};
