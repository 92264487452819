import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  GDTAction,
  GDTActionKind,
  GDTState,
  GDTStateMachine,
  GDTStateMachineType,
  GDTStateStatus,
} from "../store/reducers/google_drive_token";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.gdt,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type GDTContainerProps = PropsFromRedux & { stateMachine: GDTStateMachineType };

export const handleGDTAction = (
  prevState: GDTState,
  action: GDTAction
): GDTState => {
  switch (action.kind) {
    case GDTActionKind.TRY_SET_PROJECT: {
      return {
        ...prevState,
        project: action.project,
        folderId: action.folderId,
      };
    }
    case GDTActionKind.TRY_RESET_PROJECT: {
      return {
        ...prevState,
        project: undefined,
        folderId: undefined,
      };
    }
    case GDTActionKind.TRY_SAVE_GDT_INFO: {
      return {
        ...action,
        status: GDTStateStatus.SUCCESS,
        project: prevState.project,
      };
    }
    case GDTActionKind.TRY_RESET_ALL: {
      return {
        status: GDTStateStatus.INIT,
      };
    }
  }
};

class GDTContainer extends ReduxStateComponent3<GDTContainerProps> {
  static defaultProps = { stateMachine: GDTStateMachine };

  constructor(props: GDTContainerProps) {
    super(props);
  }

  protected onAction = handleGDTAction;
}

export default connector(GDTContainer);
