import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum FileStateStatus {
  INIT = "File::INIT",
  UPLOADING = "File::UPLOADING",
}

export enum FileActionKind {
  TRY_FETCH = "FileAction::TRY_FETCH",
  TRY_REMOVE_FILE = "FileAction::TRY_REMOVE_FILE",
  TRY_RESET = "FileAction::TRY_RESET",
  TRY_START_FILE_UPLOAD = "FileAction::TRY_START_FILE_UPLOAD",
  TRY_FINISH_FILE_UPLOAD = "FileAction::TRY_FINISH_FILE_UPLOAD",
}

interface FileStateTrait {
  readonly files: File[];
  readonly project?: { id: string; name: string };
  readonly folderId?: string;
}

export type FileState =
  | ({
      readonly status: FileStateStatus.INIT;
    } & FileStateTrait)
  | ({
      readonly status: FileStateStatus.UPLOADING;
    } & FileStateTrait);

export type FileAction =
  | {
      readonly kind: FileActionKind.TRY_FETCH;
      readonly files: File[];
      readonly project?: { id: string; name: string };
      readonly folderId?: string;
    }
  | {
      readonly kind: FileActionKind.TRY_REMOVE_FILE;
      readonly file: File;
    }
  | {
      readonly kind: FileActionKind.TRY_RESET;
    }
  | {
      readonly kind: FileActionKind.TRY_START_FILE_UPLOAD;
    }
  | {
      readonly kind: FileActionKind.TRY_FINISH_FILE_UPLOAD;
    };

export type FileError = never;

const smid = "FILE_STATE_MACHINE3";
export type FileStateMachineType = StateMachine3<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
>;
export const fileStateMachine: FileStateMachineType = new StateMachine3<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
>(smid, { status: FileStateStatus.INIT, files: [] }, [
  transition(
    FileStateStatus.INIT,
    FileStateStatus.INIT,
    FileActionKind.TRY_FETCH
  ),
  transition(
    FileStateStatus.INIT,
    FileStateStatus.INIT,
    FileActionKind.TRY_REMOVE_FILE
  ),
  transition(
    FileStateStatus.INIT,
    FileStateStatus.INIT,
    FileActionKind.TRY_RESET
  ),
  transition(
    FileStateStatus.INIT,
    FileStateStatus.UPLOADING,
    FileActionKind.TRY_START_FILE_UPLOAD
  ),
  transition(
    FileStateStatus.UPLOADING,
    FileStateStatus.INIT,
    FileActionKind.TRY_FINISH_FILE_UPLOAD
  ),
]);

/* */
export type DispatchFileAction = Dispatch<
  StateMachineAction<
    FileStateStatus,
    FileState,
    FileActionKind,
    FileAction,
    FileError
  >
>;
export default mkReducer<
  FileStateStatus,
  FileState,
  FileActionKind,
  FileAction,
  FileError
>(fileStateMachine);
export const doFileAction = (
  dispatch: DispatchFileAction,
  nextAction: FileAction,
  onResolve: () => void = () => {},
  onReject: (err: FileError | InternalError) => void = () => {}
) => {
  dispatch(fileStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doFileActionAsync = (
  dispatch: DispatchFileAction,
  nextAction: FileAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(fileStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetFile = (dispatch: DispatchFileAction) => {
  dispatch(fileStateMachine.newResetAction());
};
