import { makeStyles, Theme } from "@material-ui/core";
import { theme, service, buttons, typo } from "@theme";
import { landingTheme } from "src/styles/landingTheme";
import WaterMakr from "@assets/images/public/screen/landingHomeScreen/review/watermark.png";
import DataRoomMainBg from "@assets/images/public/screen/landingHomeScreen/data-room/bg-home-gradient.png";

const useLandingHomeStyles = makeStyles((MuiTheme: Theme) => ({
  container: {
    position: "relative",
    ...landingTheme.layout.MAX_CONTAINER,
  },
  slide_custom_counter: {
    marginTop: 60,

    "& .counter-wrap": {
      alignItems: "center",
      gap: 20,
      color: theme.gray_7_text,
    },
    "& .counter-text": {
      display: "flex",
      alignItems: "center",
      fontSize: 20,
      color: theme.gray_9_title,
      ...theme.font_medium,
      "& span": { color: theme.gray_5_body },
      "& .divider": {
        fontSize: 10,
        margin: "0 16px",
        lineHeight: "20px",
      },
    },

    "&.dark-bg": {
      "& .counter-wrap, & .counter-text": {
        color: theme.white,
      },
      "& .counter-text span": {
        color: theme.white,
        opacity: 0.4,
      },
    },
  },
  service_switch: {
    marginTop: 42,
    gap: 20,
    justifyContent: "center",

    "& .switch-button": {
      width: 240,
      height: 60,
      gap: 8,
      color: theme.gray_6,
      background: theme.gray_1,
      borderRadius: 100,
      ...typo.b1_m,
      "& svg, & path": { fill: "currentColor" },
    },
    "&& .switch-button.active-button": {
      color: theme.white,
      background: theme.primary,
    },
  },
  introduce_section: {
    width: "100%",
    padding: "136px 0",
    overflow: "hidden",
    position: "relative",
    background:
      "linear-gradient(0deg, #F6F7FC 0%, #F6F7FC 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.80) 46.22%, rgba(238, 244, 254, 0.80) 100%)",
    "& .main-content-wrap": {
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .left-content": {
      width: 590,
    },
    "& .main-subtitle": {
      letterSpacing: -2.5,
      color: theme.gray_8,
      ...landingTheme.typography.h3,
      ...theme.font_medium,
    },
    "& .main-title": {
      marginTop: 6,
      color: theme.gray_8,
      whiteSpace: "nowrap",
      ...landingTheme.typography.h1,
      ...theme.font_bold,

      "& span": {
        color: theme.primary,
      },
    },
    "& .main-description": {
      marginTop: 24,
      maxWidth: 500,

      color: theme.gray_7_text,
      ...landingTheme.typography.body2,
    },
    "& .main-button-group": {
      marginTop: 60,
      gap: 16,
    },
    "& .main-visual": {
      position: "relative",
      maxWidth: 690,
    },
    "& .main-img": {
      maxWidth: "100%",
      position: "relative",
      zIndex: 3,
    },
    "& .main-bg": {
      position: "absolute",
      left: 44,
      bottom: 0,
      zIndex: 2,
    },
    "& .line-button-mobile, & .line-button-pc, & .solid-button-contact-mobile, & .solid-button-contact-pc":
      {
        height: 60,
        padding: 0,
        borderRadius: theme.round_3,
        whiteSpace: "nowrap",
        ...landingTheme.typography.body3,
      },
    "& .line-button-mobile, & .line-button-pc": {
      padding: "0 26px",
      ...buttons.btn_primary_line,
      ...theme.font_medium,
      color: service.primary_900,
      "&:hover": {
        ...buttons.btn_primary_line_hover,
        color: service.primary_1000,
      },

      "& .button-logo-icon": {
        marginRight: 8,
        width: 103,

        "& svg": { width: "100%", height: "auto" },
      },
    },
    "& .solid-button-contact-mobile, & .solid-button-contact-pc": {
      ...buttons.btn_primary,
      ...theme.font_bold,
      width: 180,
      "&:hover": {
        ...buttons.btn_primary_hover,
      },
    },
  },

  function_section_list: { width: "100%" },
  function_section: {
    padding: "134px 0",
    background: theme.white,
    overflow: "hidden",

    "&.function_section_01 img": { transformOrigin: "bottom left" },
    "&.function_section_02 img": { transformOrigin: "bottom right" },
    "&.function_section_03 img": { transformOrigin: "bottom right" },
    "&.function_section_04 img": { transformOrigin: "bottom right" },
  },

  function_section_gray: {
    background: landingTheme.colors.CONTENT_BG_4,
    "& $function_section_content_box .function-img-box": {
      background: landingTheme.colors.CONTENT_BG_3,
    },
  },

  "@keyframes functionScrollani": {
    "0%": {
      transform: " translateY(50%)",
      opacity: 0,
    },
    "30%": { opacity: 0 },
    "100%": { transform: " translateY(0)", opacity: 1 },
  },
  function_section_content_box_active: {
    "&& .function-img-box img": {
      animation: "$functionScrollani 1s ease-in-out",
      animationFillMode: "both",
    },
    "&& .function-title, && .function-list": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },

  function_section_content_box: {
    alignItems: "center",
    justifyContent: "space-between",

    "& .function-img-box": {
      width: 502,
      height: 472,
      borderRadius: 20,
      background: landingTheme.colors.CONTENT_BG_1,
      position: "relative",

      "& img": {
        transform: " translateY(50%)",
        opacity: 0,
        position: "absolute",
      },
    },

    "& .function-description-box": {
      width: 498,
    },
    "& .function-title": {
      marginBottom: 48,
      color: theme.gray_9_title,
      letterSpacing: -1,
      ...landingTheme.typography.h2,
      ...theme.font_bold,
      transition: "all 1s ease-in-out",
      opacity: 0,
      transform: "translateY(50%)",
    },

    "& .function-list": {
      marginTop: 48,
      padding: 0,
      transition: "all 0.8s ease-in-out 0.3s",
      opacity: 0,
      transform: "translateY(50%)",
    },
    "& .function-list-item": {
      padding: 0,
      marginBottom: 16,
      gap: 20,
      letterSpacing: -1,
      color: theme.gray_8,
      ...landingTheme.typography.body2,
      ...theme.font_medium,

      "&:last-child": { marginBottom: 0 },
      "& svg": { width: 44, height: 44, flexShrink: 0 },
    },
  },

  certification_section: {
    width: "100%",
    paddingTop: 60,
    paddingBottom: 70,
    background: theme.white,
  },
  certification_section_title: {
    "&&": {
      color: theme.gray_9_title,
      letterSpacing: "-0.04em",
      ...landingTheme.typography.h3,
      ...theme.font_bold,

      "& span": {
        color: service.primary_700,
      },
    },
  },
  certification_mark_list: {
    "&&": {
      marginTop: 48,
      padding: 0,
      display: "grid",
      gridTemplateColumns: "repeat(5, minmax(190px, 220px))",
      justifyContent: "space-between",
    },
  },
  certification_mark_list_item: {
    padding: 0,
    disaply: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& img": {
      mixBlendMode: "multiply",
    },
    "& .mark-name": {
      marginTop: 20,
      color: theme.gray_7_text,
      textAlign: "center",
      ...theme.font_bold,
      ...landingTheme.typography.body2,
      letterSpacing: -1.2,
    },
    "& .mark-desc": {
      marginTop: 8,
      textAlign: "center",
      color: theme.gray_6,
      ...landingTheme.typography.body4,
      letterSpacing: -1.2,
      lineHeight: 1.5,
    },
  },
  review_section: {
    width: "100%",
    paddingTop: 80,
    paddingBottom: 90,
    color: theme.white,
    background: `${landingTheme.colors.REVIEW_BG} url(${WaterMakr}) no-repeat top right / 450px`,
  },
  review_section_title: {
    ...landingTheme.typography.h2,
    ...theme.font_bold,
  },
  review_section_sub_title: {
    marginTop: 8,
    opacity: "0.6",
    ...landingTheme.typography.body2,
    ...theme.font_medium,
  },
  review_slide_box: {
    marginTop: 40,
    marginLeft: -14,
    marginRight: -14,
  },
  review_slide_item: {
    margin: "0 14px",
    padding: 36,
    height: 300,
    display: "flex",
    flexDirection: "column",
    borderRadius: 12,
    border: 0,
    boxShadow: "1px 4px 20px 5px rgba(0,0,0, 0.04)",
    "& .slide-item-title": {
      color: theme.gray_8,
      letterSpacing: -1.6,
      ...landingTheme.typography.body1,
      ...theme.font_bold,
    },
    "& .slide-item-text": {
      marginTop: 12,
      color: theme.gray_6,
      letterSpacing: -1.2,
      ...landingTheme.typography.body3,
      lineHeight: 1.5,
    },
    "& .slide-item-bottom-box": {
      marginTop: "auto",
      alignItems: "center",
    },
    "& .slide-item-reviewer": {
      color: theme.gray_9_title,
      ...theme.font_bold,
    },
    "& .slide-item-divider": {
      margin: "0 12px",
      width: 1,
      height: 12,
      background: theme.gray_4,
    },
    "& .slide-item-logo": {
      // height: 24,
    },
  },
  slide_arrow_button: {
    "&&": {
      width: 60,
      height: 60,
      position: "absolute",
      top: "calc(50% - 15px)",

      "&::before": { display: "none", visibility: "hidden" },
      "&.slick-disabled": {
        opacity: 0.6,
      },
      "&.slick-prev": {
        left: -80,
      },
      "&.slick-next": {
        right: -80,
      },
    },
  },

  slide_append_dots: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    bottom: -50,
    "& li": {
      listStyle: "none",
    },
    "& .slick-active $slide_paging": {
      width: 78,
      borderRadius: 26,
      opacity: 1,
    },
  },
  slide_paging: {
    margin: "0 4px",
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: theme.white,
    opacity: 0.5,
    transition: "all 0.3s",
  },

  partners_section: {
    width: "100%",
    paddingTop: 100,
    paddingBottom: 100,
  },
  partners_section_title: {
    color: theme.gray_9_title,
    ...landingTheme.typography.h2,
    ...theme.font_bold,
    letterSpacing: -2,
    textAlign: "center",
  },
  partners_section_sub_title: {
    marginTop: 16,
    ...landingTheme.typography.body2,
    ...theme.font_medium,
    textAlign: "center",
    color: theme.gray_6,
    "& span": { color: service.primary_500 },
  },
  partners_section_slide: {
    marginTop: 70,
  },

  data_room_intro_section: {
    color: theme.white,
    background: `url(${DataRoomMainBg}) no-repeat center top / cover`,
    padding: "132px 0",

    "& .container": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 1320,
    },
  },

  data_room_intro_main_content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .main-content-box": {
      flexShrink: 0,
    },
    "& .sub-title": {
      marginBottom: 16,
      background: "linear-gradient(90deg, #4F8DFF 22.04%, #CE90FF 100%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "& .title": {
      margin: 0,
      lineHeight: 1.36,
      letterSpacing: "-3.2px",
      position: "relative",
      ...theme.font_light,
    },
    "& .title-top": {
      fontSize: 40,
      wordBreak: "keep-all",
    },
    "& .title-bottom": {
      fontSize: 48,
      position: "relative",
      zIndex: 2,
      "& span": { ...theme.font_bold, position: "relative", zIndex: -1 },
    },
    "& .desc": {
      marginTop: 30,
      fontSize: 20,
      whiteSpace: "pre-line",
    },
    "& .marker": {
      marginTop: 20,
      position: "absolute",
      ...theme.pos_center,
      zIndex: -1,
    },
    "& .button-group": {
      marginTop: 40,
      gap: 24,

      "& .button": {
        height: 60,
        borderRadius: 8,
        gap: 8,
        ...typo.b1_m,
        "&.white-button": {
          minWidth: 210,
          background: theme.white,
          color: service.primary_900,
          border: `1px solid ${theme.primary}`,
        },
        "&.solid-button": {
          width: 180,
          ...buttons.btn_primary,
          ...theme.font_bold,
          "&:hover": {
            ...buttons.btn_primary_hover,
          },
        },
      },
    },
    "& .main-image": {
      marginTop: -83,
      "& img": { maxWidth: "100%" },
    },
  },

  data_room_intro_sub_content: {
    marginTop: 80,
    width: "100%",
    flexDirection: "column",
    ...theme.flex_center,

    "& .sub-title": {
      marginBottom: 8,
      color: "#A28BFF",
    },
    "& .title-desc": {
      marginTop: 20,
      color: theme.gray_5_body,
      whiteSpace: "pre-line",
      textAlign: "center",
    },
    "& .sub-visual-container": {
      width: "100%",
      maxWidth: 1066,
      justifyContent: "space-between",
      marginTop: 40,
      marginLeft: "auto",
      marginRight: "auto",
      flexWrap: "wrap",
    },
    "& .sub-visual-item": {
      position: "relative",
      "& img": { maxWidth: "100%" },
      "& .sub-visual-item_content": {
        position: "absolute",
        top: 30,
        left: 24,
        right: 24,
        bottom: 20,
        color: theme.gray_4,
      },
      "& svg": { flexShrink: 0, marginRight: 20 },
    },
  },

  data_room_function_section: {
    padding: "124px 0",
    background: theme.white,
    "&.type-gray": {
      background: "#F4F6F9",
    },

    "& img": { maxWidth: "100%" },
    "& .content-box": {
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .description-box": {
      width: "100%",
      whiteSpace: "pre-line",
    },
    "& .title": {
      marginTop: 8,
      fontSize: 36,
      fontWeight: 700,
      color: theme.gray_9_title,
      lineHeight: 1.4,
    },
    "& .desc": {
      marginTop: 28,
      fontSize: 20,
      lineHeight: 1.6,
      color: theme.gray_7_text,
    },
  },

  tab_title_section: {
    paddingTop: 100,
    paddingBottom: 30,
    textAlign: "center",

    "& .tab-title": {
      marginBottom: 24,
      fontSize: 44,
      lineHeight: "1.42",
      color: theme.gray_9_title,
      whiteSpace: "pre-line",
      ...theme.font_bold,
    },
  },
  [MuiTheme.breakpoints.down(1601)]: {
    slide_arrow_button: {
      "&&": {
        width: 60,
        height: 60,
        position: "absolute",
        top: -60,

        "&.slick-prev": {
          left: "unset",
          right: 0 + 60 + 28,
        },
        "&.slick-next": {
          right: 0,
        },
      },
    },
  },
  [MuiTheme.breakpoints.down("lg")]: {
    introduce_section: {
      "& .main-visual": {
        maxWidth: 654,
      },
    },
    review_section: {
      backgroundSize: 370,
    },
    review_slide_box: {
      marginLeft: -10,
      marginRight: -10,
    },
    review_slide_item: {
      margin: "0 10px",
      padding: 28,
      height: 290,
      width: 370,
    },
  },
  [MuiTheme.breakpoints.down(landingTheme.layout.BREAK_LG)]: {
    slide_custom_counter: { ...theme.hidden },
    introduce_section: {
      paddingTop: 70,
      paddingBottom: 80,
      "& .main-content-wrap": {
        flexDirection: "column",
      },
      "& .main-visual": {
        maxWidth: 680,
        marginTop: 70,
      },
      "& .main-description": {
        marginLeft: "auto",
        marginRight: "auto",
      },
      "& .main-bg": { maxWidth: 654 },
      "& .left-content": { textAlign: "center" },
      "& .main-title": { fontSize: 60 },
      "& .main-button-group": {
        marginTop: 40,
        justifyContent: "center",
      },
    },
    data_room_intro_section: {
      paddingTop: 70,
      "&$visual_intro_section": { height: "auto" },
    },
    data_room_intro_main_content: {
      flexDirection: "column",

      "& .main-content-box": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
      "& .desc": { marginTop: 32, marginBottom: 0 },
      "& .button-group": { gap: 16 },
      "& .main-image": { marginTop: 55 },
    },
  },

  [MuiTheme.breakpoints.down(landingTheme.layout.BREAK_MD)]: {
    slide_custom_counter: {
      "& .counter-wrap": { justifyContent: "center" },
    },
    data_room_intro_section: {
      paddingTop: 70,
      paddingBottom: 80,
    },
    data_room_intro_main_content: {
      "& .main-content-box": {
        flexShrink: "unset",
      },
      "& .main-image": { maxWidth: 680 },
    },

    data_room_function_section: {
      padding: "90px 0",

      "& .content-box": { flexDirection: "column", justifyContent: "center" },
      "& .image-box": { order: 1, marginBottom: 40, maxWidth: 440 },
      "& .description-box": { order: 2, textAlign: "center" },
    },

    "@keyframes functionScrollani_md": {
      "0%": {
        transform: "scale(0.84) translateY(50%)",
        opacity: 0,
      },
      "30%": { opacity: 0 },
      "100%": { transform: "scale(0.84) translateY(0)", opacity: 1 },
    },
    function_section_content_box_active: {
      "&& .function-img-box img": {
        animation: "$functionScrollani_md 1s ease-in-out",
        animationFillMode: "both",
      },
    },

    function_section: {
      padding: "90px 0",
      "& $container": { overflow: "hidden" },
    },

    function_section_content_box: {
      flexDirection: "column",
      justifyContent: "center",

      "& .function-img-box": {
        order: 0,
        width: 425,
        height: 400,

        "& img": {
          transform: "scale(0.84)",
        },
      },
      "& .function-description-box": {
        order: 1,
        marginTop: 40,
        width: 425,
      },
      "& .function-title": { marginBottom: 40, fontSize: 32 },
      "& .function-list": { marginTop: 0 },
      "& .function-list-item": {
        marginBottom: 12,
        gap: 16,
      },
    },
    certification_section: {
      paddingBottom: 60,
    },
    certification_section_title: {
      "&&": {
        fontSize: 32,
        lineHeight: 1.4,
        textAlign: "center",
      },
    },
    certification_mark_list: {
      "&&": {
        maxWidth: 690,
        marginLeft: "auto",
        marginRight: "auto",
        gridTemplateColumns: "repeat(3, minmax(190px, 196px))",
        gap: "60px 0",
      },
    },

    slide_arrow_button: {
      display: "none",
      visibility: "hidden",
    },
    review_section_title: {
      fontSize: 32,
    },
    review_section_sub_title: {
      fontSize: 20,
    },
    review_slide_item: {
      height: 275,
      "& .slide-item-title": {
        fontSize: 20,
      },
      "& .slide-item-text": {
        fontSize: 16,
      },
    },
  },

  [MuiTheme.breakpoints.down(731)]: {
    data_room_intro_main_content: {
      "& .main-image": { maxWidth: 600 },
    },
    tab_title_section: {
      "& .tab-title": { fontSize: 38, marginBottom: 40 },
    },

    data_room_function_section: {
      padding: "52px 0",
      "& .image-box": { maxWidth: 360 },
    },
    service_switch: {
      gap: 20,

      "& .switch-button": {
        padding: "0 28px",
        flex: 1,
        maxWidth: 240,
      },
    },
    certification_section_title: {
      "&&": {
        "& span": {
          display: "block",
        },
      },
    },
    certification_mark_list: {
      "&&": {
        maxWidth: 416,
        gridTemplateColumns: "repeat(2, minmax(190px, 196px))",
        gap: "40px 0",
      },
    },
    certification_mark_list_item: {
      "& .mark-name": { fontSize: 18 },
      "& .mark-desc": { fontSize: 14 },
    },
    review_section: {
      paddingTop: 52,
      paddingBottom: 72,
      backgroundSize: 270,
    },
    review_slide_item: {
      padding: "32px 28px",
      height: 270,
    },
    partners_section_title: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 300,
      fontSize: 32,
      lineHeight: 1.5,
    },
    partners_section_sub_title: {
      fontSize: 20,
    },
    partners_section_slide: {
      marginTop: 40,
    },
  },
  [MuiTheme.breakpoints.down(621)]: {
    introduce_section: {
      "& .left-content": {
        width: "100%",
      },
      "& .main-visual": {
        maxWidth: 434,
      },
      "& .main-bg": { maxWidth: 490 },
      "& .main-description": {
        marginTop: 16,
        fontSize: 18,
      },
      "& .main-button-group": {
        marginTop: 24,
      },
    },
    function_section: {
      padding: "60px 0",
    },
    "@keyframes functionScrollani_sm": {
      "0%": {
        transform: "scale(0.67) translateY(50%)",
        opacity: 0,
      },
      "30%": { opacity: 0 },
      "100%": { transform: "scale(0.67) translateY(0)", opacity: 1 },
    },
    function_section_content_box_active: {
      "&& .function-img-box img": {
        animation: "$functionScrollani_sm 1s ease-in-out",
        animationFillMode: "both",
      },
    },
    function_section_content_box: {
      "& .function-img-box": {
        width: 340,
        height: 320,

        "& img": {
          transform: "scale(0.67)",
        },
      },
      "& .function-description-box": {
        width: 340,
      },
      "& .function-title": { marginBottom: 28, fontSize: 24 },
      "& .function-list-item": {
        marginBottom: 12,
        gap: 12,
        fontSize: 18,
        alignItems: "flex-start",

        "& svg": { width: 36, height: 36 },
      },
    },
    review_section: {
      backgroundSize: 178,
    },
  },
  [MuiTheme.breakpoints.down(601)]: {
    slide_custom_counter: {
      marginTop: 40,
      "& .counter-text": { fontSize: 16 },
    },
    tab_title_section: {
      "& .tab-title": { marginBottom: 24 },
    },

    data_room_intro_section: {},
    data_room_intro_main_content: {
      "& .main-image": { maxWidth: 500 },
      "& .sub-title": {
        fontSize: 18,
      },
      "& .title-top": { fontSize: 32, textAlign: "center" },
      "& .desc": {
        ...theme.hidden,
      },

      "& .button-group": {
        gap: 12,
        justifyContent: "center",
        "& .button": {
          fontSize: 14,
          height: 40,
          "&.white-button": {
            minWidth: 0,
            padding: "0 16px",
            whiteSpace: "noWrap",
          },
          "&.solid-button": {
            maxWidth: 124,
            width: 124,
          },

          "& svg": { width: 74 },
        },
      },
    },
    data_room_function_section: {
      "& .image-box": { maxWidth: 284 },
      "& .title": { fontSize: 28 },
      "& .desc": { fontSize: 14, marginTop: 20 },
    },
    introduce_section: {
      "& .main-visual": {
        maxWidth: 292,
      },
      "& .main-bg": { maxWidth: 332, left: 8 },
      "& .main-subtitle": { fontSize: 20 },
      "& .main-title": { fontSize: 40 },
      "& .main-description": {
        fontSize: 14,
      },
      "& .main-button-group": {
        gap: 12,
      },
      "& .line-button-mobile, & .line-button-pc, & .solid-button-contact-mobile, & .solid-button-contact-pc":
        {
          height: 44,
          fontSize: 14,
        },
      "& .line-button-mobile, & .line-button-pc": {
        padding: "0 20px",
        "& .button-logo-icon": { marginRight: 6, width: 74 },
      },
      "& .solid-button-contact-mobile, & .solid-button-contact-pc": {
        width: 136,
      },
    },
    function_section: {
      padding: "52px 0",
    },
    "@keyframes functionScrollani_xs": {
      "0%": {
        transform: "scale(0.507) translateY(50%)",
        opacity: 0,
      },
      "30%": { opacity: 0 },
      "100%": { transform: "scale(0.507) translateY(0)", opacity: 1 },
    },
    function_section_content_box_active: {
      "&& .function-img-box img": {
        animation: "$functionScrollani_xs 0.8s ease-in-out",
        animationFillMode: "both",
      },
    },
    function_section_content_box: {
      "& .function-img-box": {
        width: 255,
        height: 240,

        "& img": {
          transform: "scale(0.507)",
        },
      },
      "& .function-description-box": {
        marginTop: 32,
        width: "100%",
        maxWidth: 255,
      },
      "& .function-title": { marginBottom: 16, fontSize: 20 },
      "& .function-list-item": {
        marginBottom: 8,
        gap: 8,
        fontSize: 14,

        "& svg": { width: 28, height: 28 },
      },
    },
    certification_section: {
      paddingTop: 40,
      paddingBottom: 40,
    },
    certification_section_title: {
      "&&": {
        fontSize: 22,
      },
    },
    certification_mark_list: {
      "&&": {
        marginTop: 24,
        gridTemplateColumns: "repeat(1, minmax(190px, 196px))",
        gap: "36px 0",
        justifyContent: "center",
      },
    },
    review_section: {
      padding: "40px 0 44px 0",
    },
    review_section_title: { fontSize: 22 },
    review_section_sub_title: { marginTop: 16, fontSize: 16 },
    review_slide_box: {
      marginTop: 36,
    },
    review_slide_item: {
      padding: 22,
      width: 260,
      height: 208,
      borderRadius: 8,
      "& .slide-item-title": { fontSize: 14 },
      "& .slide-item-text": { fontSize: 13, marginTop: 8 },
      "& .slide-item-reviewer": { fontSize: 12 },
      "& .slide-item-divider": { height: 8, margin: "0 8px" },
      "& .slide-item-logo": { height: 16 },
    },
    partners_section: {
      paddingTop: 40,
      paddingBottom: 48,
    },
    partners_section_title: {
      maxWidth: "100%",
      fontSize: 22,
    },
    partners_section_sub_title: {
      fontSize: 16,
    },
  },
  [MuiTheme.breakpoints.down("xs")]: {
    introduce_section: {
      "& .line-button-mobile, & .line-button-pc": {
        padding: "0 12px",
      },
      "& .solid-button-contact-mobile, & .solid-button-contact-pc": {
        width: 114,
      },
    },
    data_room_intro_section: { overflow: "hidden" },
    data_room_intro_main_content: {
      "& .title-top, & .title-bottom": { whiteSpace: "pre-line" },
      "& .main-image": { width: "calc(124vw)" },
    },
    tab_title_section: {
      paddingTop: 50,
      "& .tab-title": { fontSize: 28 },
      "& .text": { whiteSpace: "pre-line" },
    },
    service_switch: {
      marginTop: 40,
      "& .switch-button svg": { ...theme.hidden },
    },
    review_section: {
      backgroundSize: 162,
    },
  },
}));

export default useLandingHomeStyles;
