export default {
  ko: {
    list: {
      auth: {
        empty_auth: "인증 받은 연구노트가 아직 없어요.",
        guide_add_note: "연구노트 추가 버튼을 눌러 노트를 추가해보세요!",
        guide_google_drive: "구글 드라이브에서 추가도 가능해요.",
        block_num: "번째 블록",
        folder: "저장 폴더",
        sign: "전자 서명",
        hash: "해시",
        auth: "인증",
      },
      strong_box: {
        name: "이름",
        file: "파일",
        member: "멤버",
      },
      document_box_file: {
        file_name: "파일명",
        share_link: (_windowWidth: number) => "공유 링크",
        creator: "생성자",
      },
      nda_template: {
        nda_name: "NDA 파일명",
        signed: "서명 내역",
        share_link: "연결된 링크",
        creator: "생성자",
      },
      signed_nda: {
        template: "NDA 템플릿",
        file: "파일",
        user: "서명 참여자",
        date: "서명 날짜",
        contract: "계약서",
      },
      nda_template_signed: {
        file_name: "파일명",
        link_name: "링크 이름",
        user: "서명 참여자",
        date: "서명 날짜",
        contract: "계약서",
      },
    },
  },
  en: {
    list: {
      auth: {
        empty_auth: "Don't have any certified research notes.",
        guide_add_note: "Add notes by clicking on 'add research note' button!",
        guide_google_drive: "You can also add through Google Drive.",
        block_num: " Blocks",
        folder: "Folder",
        sign: "Signature",
        hash: "Hash",
        auth: "Certification",
      },
      strong_box: {
        name: "Name",
        file: "Files",
        member: "Members",
      },
      document_box_file: {
        file_name: "File name",
        share_link: (windowWidth: number) =>
          windowWidth < 601 ? "Links" : "Custom links",
        creator: "Owner",
      },
      nda_template: {
        nda_name: "NDA name",
        signed: "Sigend history",
        share_link: "Connected links",
        creator: "Owner",
      },
      signed_nda: {
        template: "NDA Templates",
        file: "File",
        user: "Signed by",
        date: "Signed at",
        contract: "NDA contract",
      },
      nda_template_signed: {
        file_name: "File name",
        link_name: "Link name",
        user: "Signed by",
        date: "Signed at",
        contract: "NDA contract",
      },
    },
  },
};
