import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { TokenStateStatus } from "src/redux/store/reducers/token";
import {
  changeWorkspaceOwner,
  createFreeWorkspacePlan,
  createWorkspace,
  getAllWorkspaces,
  getWorkspace,
  inviteWorkspaceMember,
  removeWorkspace,
  removeWorkspaceMember,
  removeWorkspaceMembers,
  updateWorkspace,
  updateWorkspaceMemberRole,
} from "@goono-react-commons/services/workspace";
import {
  SnackbarActionKind,
  SnackbarType,
  doSnackbarActionAsync,
} from "src/redux/store/reducers/snackbar";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import { useWorkspaceInfo } from "@utils/hooks/service/useWorkspaceInfo";
import translate from "@translate";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import {
  ChangeWorkspaceOwnerParams,
  InviteWorkspaceMemberParams,
  RemoveWorkspaceMemberParams,
  RemoveWorkspaceMembersParams,
  UpdateWorkspaceMemberRoleParams,
} from "@goono-commons/v3/request/workspace";
import { Workspace, WorkspaceRoleKind } from "@goono-commons/v3/workspace";
import { ICreateTrialWorkspacePlan } from "@goono-commons/v3/request/manager";
import { switchFileToIPFSData } from "@react-query/utils/switchFileToIPFSData";
import { getWorkspaceRoleLevel } from "@goono-commons/api/object/workspace";

export const WORKSPACE_NAME_MAX_LENGTH = 100;

export enum WorkspaceQueryKey {
  getJoinedWorkspaceList = "WorkspaceQueryKey::getJoinedWorkspaceList",
  getWorkspaceAcessAllow = "WorkspaceQueryKey::getWorkspaceAcessAllow",
}

export type UseCreateWorkspaceMutationParams = {
  name: string;
  profile_color: string;
  new_thumbnail?: File;
};

/** 유저가 소속되어있는 워크스페이스를 전체 조회 */
export const useGetJoinedWorkspaceListQuery = () => {
  const selector = useSelector((state: RootState) => state);
  const tokenState = selector.token.state;

  const token =
    tokenState.status === TokenStateStatus.SUCCESS
      ? tokenState.token
      : "unknown";

  return useQuery(
    [WorkspaceQueryKey.getJoinedWorkspaceList],
    async () => {
      return {
        workspaceList: await getAllWorkspaces(token),
      };
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export const useCreateWorkspaceMutation = () => {
  const userInfo = useLoginUserInfo();
  const token = userInfo.token;

  return useMutation(async (params: UseCreateWorkspaceMutationParams) => {
    if (params.new_thumbnail) {
      const thumbInfo = await switchFileToIPFSData(token, params.new_thumbnail);
      return await createWorkspace({
        token,
        reqBody: {
          name: params.name,
          thumbnail: thumbInfo,
          thumbnail_color: params.profile_color,
        },
      });
    } else {
      return await createWorkspace({
        token,
        reqBody: { name: params.name, thumbnail_color: params.profile_color },
      });
    }
  });
};

export const useEditWorkspaceMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(
    async (props: { new_name: string; new_thumbnail_file?: File }) => {
      if (props.new_thumbnail_file) {
        const thumbInfo = await switchFileToIPFSData(
          userInfo.token,
          props.new_thumbnail_file
        );
        await updateWorkspace(userInfo.token, workspaceInfo.id, {
          name: props.new_name,
          thumbnail: thumbInfo,
        });
      } else {
        await updateWorkspace(userInfo.token, workspaceInfo.id, {
          name: props.new_name,
        });
      }
    },
    {
      onSuccess: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: translate.snackbar.updated,
        });

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useRemoveWorkspaceMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();
  const history = useHistory();

  return useMutation(
    async () => {
      await removeWorkspace(userInfo.token, workspaceInfo.id);
    },
    {
      onSuccess: async () => {
        history.push(ScreenURL.RESEARCH_NOTE_DASHBOARD);

        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: translate.alert.success_delete,
        });

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useLeaveWorkspaceMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();
  const history = useHistory();

  return useMutation(
    async () => {
      await removeWorkspaceMember(userInfo.token, workspaceInfo.id, {
        email: userInfo.email,
      });
    },
    {
      onSuccess: async () => {
        history.push(ScreenURL.RESEARCH_NOTE_DASHBOARD);

        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: `워크스페이스에서 나갔습니다.`,
        });

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useUpdateWorkspaceMemberRoleMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(
    async (props: UpdateWorkspaceMemberRoleParams) => {
      await updateWorkspaceMemberRole(userInfo.token, workspaceInfo.id, props);
    },
    {
      onSuccess: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: translate.snackbar.updated,
        });

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useInviteWorkspaceMemberMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(
    async (props: InviteWorkspaceMemberParams) => {
      await inviteWorkspaceMember(userInfo.token, workspaceInfo.id, props);
      return props.email;
    },
    {
      onSuccess: async (userEmail) => {
        const freshWorkspace = await getWorkspace(
          userInfo.token,
          workspaceInfo.id
        );

        if (
          freshWorkspace.pendingMembers.find(
            (pendingMember) => pendingMember.email === userEmail
          ) !== undefined
        ) {
          await doSnackbarActionAsync(dispatch, {
            kind: SnackbarActionKind.TRY_OPEN,
            type: SnackbarType.CONFIRM,
            msg: translate.alert.success_send_email,
          });
        } else {
          await doSnackbarActionAsync(dispatch, {
            kind: SnackbarActionKind.TRY_OPEN,
            type: SnackbarType.CONFIRM,
            msg: translate.alert.success_invite_workspace,
          });
        }

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useTransferWorkspaceOwnerMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(
    async (props: ChangeWorkspaceOwnerParams) => {
      await changeWorkspaceOwner(userInfo.token, workspaceInfo.id, props);
    },
    {
      onSuccess: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: translate.snackbar.owner_updated,
        });

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useRemoveWorkspaceMembersMutation = () => {
  const dispatch = useDispatch();
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(
    async (props: RemoveWorkspaceMembersParams) => {
      await removeWorkspaceMembers(userInfo.token, workspaceInfo.id, props);
    },
    {
      onSuccess: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: translate.alert.success_delete,
        });

        await queryClient.invalidateQueries(
          WorkspaceQueryKey.getJoinedWorkspaceList
        );
      },
      onError: async () => {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: translate.snackbar.problem,
        });
      },
    }
  );
};

export const useCreateFreeWorkspacePlanMutation = () => {
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(async (props: ICreateTrialWorkspacePlan) => {
    await createFreeWorkspacePlan(userInfo.token, workspaceInfo.id, props);

    await queryClient.invalidateQueries([
      WorkspaceQueryKey.getJoinedWorkspaceList,
    ]);
  });
};

export const useRemovePendingWorkspaceMemberMutation = () => {
  const userInfo = useLoginUserInfo();
  const queryClient = useQueryClient();
  const workspaceInfo = useWorkspaceInfo();

  return useMutation(async (props: RemoveWorkspaceMemberParams) => {
    await removeWorkspaceMember(userInfo.token, workspaceInfo.id, props);

    await queryClient.invalidateQueries([
      WorkspaceQueryKey.getJoinedWorkspaceList,
    ]);
  });
};

/** ip 접근제한 확인 */
export const useGetWorkspaceAcessAllowQuery = (
  workspace: Workspace | undefined
) => {
  const userInfo = useLoginUserInfo();

  return useQuery(
    [WorkspaceQueryKey.getWorkspaceAcessAllow, workspace?.id],
    async () => {
      if (workspace === undefined) return true;
      if (workspace.plan?.aclOption?.enableACL !== true) return true;

      try {
        /** 관리자 이상은 그냥 ip 상관없이 허용 */
        if (workspace.plan.aclOption.applyAdmin !== true) {
          const workspaceRole = workspace.members.find(
            (m) => m.id === userInfo.id
          )?.role;

          if (
            getWorkspaceRoleLevel(workspaceRole) >=
            getWorkspaceRoleLevel(WorkspaceRoleKind.ADMIN)
          ) {
            return true;
          }
        }

        await getWorkspace(userInfo.token, workspace.id);
        return true;
      } catch (err) {
        const errCode = (err as any)?.code;
        return errCode === 401 ? false : true;
      }
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};
