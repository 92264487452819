import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import { UserObject } from "../../../goono-commons/api/object/user";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { GoonoLoginArgs } from "@goono-react-commons/services/user";

export enum TokenStateStatus {
  INIT = "TokenState::INIT",
  SUCCESS_SNS_SIGN_UP = "TokenState::SUCCESS_SNS_SIGN_UP",
  REGISTER_EMAIL_AVAILABLE = "TokenState::REGISTER_EMAIL_AVAILABLE",
  SUCCESS_REGISTER_EMAIL = "TokenState::SUCCESS_REGISTER_EMAIL",
  SUCCESS = "TokenState::SUCCESS",
  READY_UPDATE_PW = "TokenState::READY_UPDATE_PW",
}

export enum TokenActionKind {
  TRY_SAVE_USER_INFO = "TokenAction::TRY_SAVE_USER_INFO",
  TRY_UPDATE_PROFILE_INFO = "TokenAction::TRY_UPDATE_PROIFLE_INFO",
  TRY_SAVE_SNS_SIGN_UP_INFO = "TokenAction::TRY_SAVE_SNS_SIGN_UP_INFO",
  TRY_INIT_TOKEN = "TokenAction::TRY_INIT_TOKEN",
  TRY_SAVE_PW_TOKEN = "TokenAction::TRY_SAVE_PW_TOKEN",
  TRY_UPDATE_PW = "TokenAction::TRY_UPDATE_PW",
}
export type TokenError = never;

export enum TokenErrorType {
  EMAIL_NOT_VERIFY = "TokenErrorType::EMAIL_NOT_VERIFY",
  SEND_VERIFY_MAIL_AGAIN = "TokenErrorType::SEND_VERIFY_MAIL_AGAIN",
  PW_INVALID = "TokenErrorType::PW_INVALID",
  INCORRECT_PLATFORM = "TokenErrorType::INCORRECT_PLATFORM",
  ALREADY_REGISTERED = "TokenErrorType::ALREADY_REGISTERED",
  NOT_REGISTERED = "TokenErrorType::NOT_REGISTER",
}

export type LoginUserInfo = {
  readonly token: string;
} & UserObject;

export type TokenState =
  | {
      readonly status: TokenStateStatus.INIT;
    }
  | {
      readonly status: TokenStateStatus.SUCCESS_SNS_SIGN_UP;
      readonly args: GoonoLoginArgs;
    }
  | ({
      readonly status: TokenStateStatus.SUCCESS;
    } & LoginUserInfo)
  | {
      readonly status: TokenStateStatus.READY_UPDATE_PW;
      readonly pwToken: string;
    }
  | {
      readonly status: TokenStateStatus.SUCCESS_REGISTER_EMAIL;
    };

export type TokenAction =
  | ({
      readonly kind: TokenActionKind.TRY_SAVE_USER_INFO;
      readonly token: string;
    } & UserObject)
  | {
      readonly kind: TokenActionKind.TRY_UPDATE_PROFILE_INFO;
      readonly userInfo: UserObject;
    }
  | {
      readonly kind: TokenActionKind.TRY_SAVE_SNS_SIGN_UP_INFO;
      readonly args: GoonoLoginArgs;
    }
  | {
      readonly kind: TokenActionKind.TRY_INIT_TOKEN;
    }
  | {
      readonly kind: TokenActionKind.TRY_SAVE_PW_TOKEN;
      readonly pwToken: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_UPDATE_PW;
    };

const smid = "TOKEN_STATE_MACHINE3";
export type TokenStateMachineType = StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>;
export const tokenStateMachine: TokenStateMachineType = new StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(smid, { status: TokenStateStatus.INIT }, [
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_SAVE_USER_INFO
  ),
  transition(
    TokenStateStatus.SUCCESS_REGISTER_EMAIL,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_SAVE_USER_INFO
  ),
  transition(
    TokenStateStatus.SUCCESS_SNS_SIGN_UP,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_SAVE_USER_INFO
  ),

  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_UPDATE_PROFILE_INFO
  ),

  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS_SNS_SIGN_UP,
    TokenActionKind.TRY_SAVE_SNS_SIGN_UP_INFO
  ),

  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_INIT_TOKEN
  ),

  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.READY_UPDATE_PW,
    TokenActionKind.TRY_SAVE_PW_TOKEN
  ),
  transition(
    TokenStateStatus.READY_UPDATE_PW,
    TokenStateStatus.READY_UPDATE_PW,
    TokenActionKind.TRY_SAVE_PW_TOKEN
  ),
  transition(
    TokenStateStatus.READY_UPDATE_PW,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_UPDATE_PW
  ),
]);

export type DispatchTokenAction = Dispatch<
  StateMachineAction<
    TokenStateStatus,
    TokenState,
    TokenActionKind,
    TokenAction,
    TokenError
  >
>;
export default mkReducer<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(tokenStateMachine);

export const doTokenAction = (
  dispatch: DispatchTokenAction,

  nextAction: TokenAction,
  onResolve: () => void = () => {},
  onReject: (err: TokenError | InternalError) => void = () => {}
) => {
  dispatch(tokenStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doTokenActionAsync = (
  dispatch: DispatchTokenAction,
  nextAction: TokenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(tokenStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetToken = (dispatch: DispatchTokenAction) => {
  dispatch(tokenStateMachine.newResetAction());
};
