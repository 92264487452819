import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { SortingFilterOptions } from "@utils/functions/sortingFilter";
import { IGetProjectActivity } from "@goono-commons/v3/request/activityLog";

export enum ProjectStateStatus {
  INIT = "ProjectState::INIT",
  SELECT = "ProjectState::SELECT",
}

export enum ProjectActionKind {
  TRY_SELECT_PROJECT = "ProjectAction::TRY_SELECT_PROJECT",
  TRY_UNSELECT_PROJECT = "ProjectAction::TRY_UNSELECT_PROJECT",
  TRY_UPDATE_SORT_ORDER = "ProjectAction::TRY_UPDATE_SORT_ORDER",
  TRY_UPDATE_ACTIVITY_FILTER = "ProjectAction::TRY_UPDATE_ACTIVITY_FILTER",
}

export type ProjectError = never;

export enum ProjectErrorType {
  MAXIMUM_PIN = "ProjectErrorType::MAXIMUM_PIN",
}

export type ProjectState =
  | {
      readonly status: ProjectStateStatus.INIT;
      readonly projectSortingOption?: SortingFilterOptions;
      readonly projectIdTmp?: string;
      readonly activityFilterOption?: IGetProjectActivity;
    }
  | {
      readonly status: ProjectStateStatus.SELECT;
      readonly projectId: string;
      readonly folderId?: string;
      readonly projectIdTmp?: string;
      readonly activityFilterOption?: IGetProjectActivity;
      readonly projectSortingOption?: SortingFilterOptions;
    };

export type ProjectAction =
  | {
      readonly kind: ProjectActionKind.TRY_SELECT_PROJECT;
      projectId: string;
      folderId?: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_UNSELECT_PROJECT;
    }
  | {
      readonly kind: ProjectActionKind.TRY_UPDATE_SORT_ORDER;
      readonly projectSortingOption?: SortingFilterOptions;
    }
  | {
      readonly kind: ProjectActionKind.TRY_UPDATE_ACTIVITY_FILTER;
      newOption?: IGetProjectActivity;
    };

const smid = "PROJECT_STATE_MACHINE3";
export type ProjectStateMachineType = StateMachine3<
  ProjectStateStatus,
  ProjectState,
  ProjectActionKind,
  ProjectAction,
  ProjectError
>;
export const projectStateMachine: ProjectStateMachineType = new StateMachine3<
  ProjectStateStatus,
  ProjectState,
  ProjectActionKind,
  ProjectAction,
  ProjectError
>(smid, { status: ProjectStateStatus.INIT }, [
  transition(
    ProjectStateStatus.INIT,
    ProjectStateStatus.SELECT,
    ProjectActionKind.TRY_SELECT_PROJECT
  ),
  transition(
    ProjectStateStatus.SELECT,
    ProjectStateStatus.SELECT,
    ProjectActionKind.TRY_SELECT_PROJECT
  ),
  transition(
    ProjectStateStatus.SELECT,
    ProjectStateStatus.INIT,
    ProjectActionKind.TRY_UNSELECT_PROJECT
  ),
  transition(
    ProjectStateStatus.INIT,
    ProjectStateStatus.INIT,
    ProjectActionKind.TRY_UPDATE_SORT_ORDER
  ),
  transition(
    ProjectStateStatus.SELECT,
    ProjectStateStatus.SELECT,
    ProjectActionKind.TRY_UPDATE_SORT_ORDER
  ),
  transition(
    ProjectStateStatus.SELECT,
    ProjectStateStatus.SELECT,
    ProjectActionKind.TRY_UPDATE_ACTIVITY_FILTER
  ),
  transition(
    ProjectStateStatus.INIT,
    ProjectStateStatus.INIT,
    ProjectActionKind.TRY_UPDATE_ACTIVITY_FILTER
  ),
]);

export type DispatchProjectAction = Dispatch<
  StateMachineAction<
    ProjectStateStatus,
    ProjectState,
    ProjectActionKind,
    ProjectAction,
    ProjectError
  >
>;
export default mkReducer<
  ProjectStateStatus,
  ProjectState,
  ProjectActionKind,
  ProjectAction,
  ProjectError
>(projectStateMachine);

export const doProjectAction = (
  dispatch: DispatchProjectAction,

  nextAction: ProjectAction,
  onResolve: () => void = () => {},
  onReject: (err: ProjectError | InternalError) => void = () => {}
) => {
  dispatch(projectStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doProjectActionAsync = (
  dispatch: DispatchProjectAction,
  nextAction: ProjectAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(projectStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetProject = (dispatch: DispatchProjectAction) => {
  dispatch(projectStateMachine.newResetAction());
};
