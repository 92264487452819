import { IpfsData } from "@goono-commons/api/object/ipfs";
import { getIPFSUrl } from "@goono-react-commons/services/ipfs";
import { useGetIpfsUrlDataQuery } from "@react-query/api/ipfs";
import React from "react";

type HTMLProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

type Props = {
  src?: string;
  ipfs_src?: IpfsData;
} & HTMLProps;

export default function CachedImage(props: Props) {
  const ipfsUrlDataQuery = useGetIpfsUrlDataQuery(props.ipfs_src);

  const default_img = getIPFSUrl(
    "QmSRNyHrKMDuyNR5RvkY6u2DzvuhiHD8gyqyd6YijPJrPX",
    "png"
  );

  if (!ipfsUrlDataQuery.isSuccess) return <img {...props} src={default_img} />;

  return <img {...props} src={props.src ?? ipfsUrlDataQuery.data.url} />;
}
