import React from "react";
import { Box, Container } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useLandingHomeStyles from "../styles";
import clsx from "clsx";
import { theme, system, ect } from "@theme";
import translate from "@translate";

import FunctionImage01 from "@assets/images/public/screen/landingHomeScreen/data-room/function-image-01.png";
import FunctionImage02 from "@assets/images/public/screen/landingHomeScreen/data-room/function-image-02.png";
import FunctionImage03 from "@assets/images/public/screen/landingHomeScreen/data-room/function-image-03.png";
import FunctionImage04 from "@assets/images/public/screen/landingHomeScreen/data-room/function-image-04.png";

const functionSections = [
  {
    color: system.warning_900,
    title: translate.landing_home.data_room_function.func_01.title,
    subtitle: translate.landing_home.data_room_function.func_01.subtitle,
    description: translate.landing_home.data_room_function.func_01.description,
    image: FunctionImage01,
    type: "",
  },
  {
    color: theme.primary,
    title: translate.landing_home.data_room_function.func_02.title,
    subtitle: translate.landing_home.data_room_function.func_02.subtitle,
    description: translate.landing_home.data_room_function.func_02.description,
    image: FunctionImage02,
    type: "type-gray",
  },
  {
    color: ect.deep_purple_500,
    title: translate.landing_home.data_room_function.func_03.title,
    subtitle: translate.landing_home.data_room_function.func_03.subtitle,
    description: translate.landing_home.data_room_function.func_03.description,
    image: FunctionImage03,
    type: "",
  },
  {
    color: "#25AD9B",
    title: translate.landing_home.data_room_function.func_04.title,
    subtitle: translate.landing_home.data_room_function.func_04.subtitle,
    description: translate.landing_home.data_room_function.func_04.description,
    image: FunctionImage04,
    type: "type-gray",
  },
];
export default function DataRoomFunctionSection() {
  const classes = useLandingHomeStyles();

  return (
    <Box>
      {functionSections.map((section, index) => (
        <Box
          key={index}
          className={clsx(classes.data_room_function_section, section.type)}
        >
          <Container className={classes.container}>
            <Flex container className="content-box">
              {index % 2 === 0 ? (
                <>
                  <Box className="description-box">
                    <GoonoTypography
                      type="h5"
                      style={{ color: section.color }}
                      children={section.title}
                    />
                    <GoonoTypography
                      className="title"
                      children={section.subtitle}
                    />
                    <GoonoTypography
                      className="desc"
                      children={section.description}
                    />
                  </Box>
                  <Box className="image-box">
                    <img src={section.image} alt="" />
                  </Box>
                </>
              ) : (
                <>
                  <Box className="image-box">
                    <img src={section.image} alt="" />
                  </Box>
                  <Box className="description-box">
                    <GoonoTypography
                      type="h5"
                      style={{ color: section.color }}
                      children={section.title}
                    />
                    <GoonoTypography
                      className="title"
                      children={section.subtitle}
                    />
                    <GoonoTypography
                      className="desc"
                      children={section.description}
                    />
                  </Box>
                </>
              )}
            </Flex>
          </Container>
        </Box>
      ))}
    </Box>
  );
}
