import { IpfsData } from "@goono-commons/api/object/ipfs";
import { imageExtensionList } from "@goono-commons/api/request/note";
import { getIPFSUrlDecrypted } from "@goono-react-commons/services/ipfs";
import { hasExtensionInFileName } from "@utils/functions/hasExtensionInFileName";
import { useLoginUserInfo } from "@utils/hooks/service/useLoginUserInfo";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import {
  SnackbarActionKind,
  SnackbarType,
  doSnackbarActionAsync,
} from "src/redux/store/reducers/snackbar";
import { TokenStateStatus } from "src/redux/store/reducers/token";

export enum IPFSQueryKey {
  getIpfsUrlData = "IPFSQueryKey::getIpfsUrlData",
  getIpfsUrlDataWithFilter = "IPFSQueryKey::getIpfsUrlDataWithFilter",
}

export type IpfsUrlData = {
  type: "img" | "pdf";
  url: string;
};

const getIPFSUrlData = async (
  ipfs_src: IpfsData | undefined,
  token: string
): Promise<IpfsUrlData> => {
  if (ipfs_src === undefined)
    return {
      type: "img",
      url: ``,
    };

  const { uri, init } = getIPFSUrlDecrypted(
    token,
    ipfs_src.cid,
    ipfs_src.extension,
    ipfs_src.CipherId
  );

  const res = await fetch(uri, init);
  const blob = await res.blob();

  const mimeType =
    ipfs_src.extension.toLowerCase() === "pdf"
      ? "application/pdf"
      : `image/${ipfs_src.extension.toLowerCase()}`;

  const blobWithMime = blob.slice(0, blob.size, mimeType);

  return {
    type: ipfs_src.extension.toLowerCase() === "pdf" ? "pdf" : "img",
    url: window.URL.createObjectURL(blobWithMime),
  };
};

export const useGetIpfsUrlDataQuery = (ipfs_src: IpfsData | undefined) => {
  const tokenState = useSelector((state: RootState) => state.token).state;

  const token =
    tokenState.status === TokenStateStatus.SUCCESS ? tokenState.token : ``;

  return useQuery<IpfsUrlData>(
    [IPFSQueryKey.getIpfsUrlData, ipfs_src?.cid ?? `none`],
    async () => {
      return await getIPFSUrlData(ipfs_src, token);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

/** ipfs 파일 뷰어에 실제로 띄울 수 있는 확장자들 ( 이미지 + pdf ) */
export const allowed_extension_with_ipfs_viewer = [
  ...imageExtensionList,
  "pdf",
];

export const useGetIpfsUrlDataWithFilterQuery = (
  ipfs_src: IpfsData,
  disabled?: boolean
) => {
  const tokenState = useSelector((state: RootState) => state.token).state;

  const token =
    tokenState.status === TokenStateStatus.SUCCESS ? tokenState.token : ``;

  return useQuery<IpfsUrlData | undefined>(
    [
      IPFSQueryKey.getIpfsUrlDataWithFilter,
      ipfs_src.cid ?? `none`,
      ipfs_src.extension,
    ],
    async () => {
      if (!ipfs_src || disabled) return undefined;

      if (
        allowed_extension_with_ipfs_viewer.includes(
          ipfs_src.extension.toLowerCase()
        )
      )
        return await getIPFSUrlData(ipfs_src, token);
      else return undefined;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

/** with token */
export const useDownloadOriginFileMutation = () => {
  const { token } = useLoginUserInfo();
  const dispatch = useDispatch();

  return useMutation(
    async (props: { ipfsData: IpfsData; fileName: string }) => {
      await doSnackbarActionAsync(dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.LOADING,
        duration: Infinity,
        msg: "다운로드 중 입니다..",
      });

      try {
        const urlInfo = await getIPFSUrlData(props.ipfsData, token);
        const link = document.createElement("a");

        link.href = urlInfo.url;

        link.download = hasExtensionInFileName(props.fileName)
          ? props.fileName
          : `${props.fileName}.${props.ipfsData.extension}`;

        link.click();
        link.remove();

        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: "다운로드가 완료되었습니다.",
        });
      } catch {
        await doSnackbarActionAsync(dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.ALERT,
          msg: "문제가 발생하였습니다.",
        });
      }
    }
  );
};
